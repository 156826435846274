import Vue from 'vue';
import Vuex from 'vuex';
import {Metrics, Team, User} from '@/types/domain';
import {StoreState} from '@/types/app';
// import createMultiTabState from 'vuex-multi-tab-state';

Vue.use(Vuex);

const KEY_USER = 'user';
const KEY_MAIL = 'mail';
const KEY_SELECTED_TEAM = 'selectedTeam';
const KEY_METRICS_DISMISSED_AT = '_mda';
const KEY_LAST_DATE_OF_BIRTH = '_ldob';

const getFromLocalStorage = (key: string) => {
    const stringRepresentation = localStorage.getItem(key);
    try {
        if (stringRepresentation && stringRepresentation !== 'undefined' && stringRepresentation !== 'null') {
            return JSON.parse(stringRepresentation);
        }
    } catch (e) {
        return stringRepresentation;
    }
};

const state: StoreState = {
    user: getFromLocalStorage(KEY_USER),
    mail: getFromLocalStorage(KEY_MAIL),
    csrfToken: undefined, // do not persist this token! only store it in-memory
    selectedTeam: getFromLocalStorage(KEY_SELECTED_TEAM),
    numPendingEvents: 0,
    numPendingUsers: 0,
    metricsDismissedAt: getFromLocalStorage(KEY_METRICS_DISMISSED_AT),
    lastDateOfBirth: getFromLocalStorage(KEY_LAST_DATE_OF_BIRTH),
    appVersion: require('../../package.json').version,
};

export default new Vuex.Store({
    state,
    mutations: {
        auth_success(state, user: User) {
            state.user = user;
        },
        register_success(state, mail: string) {
            state.mail = mail;
        },
        logout(state) {
            state.user = undefined;
            state.selectedTeam = undefined;
            state.numPendingEvents = 0;
            state.numPendingUsers = 0;
        },
        deactivate(state) {
            state.user = undefined;
            state.selectedTeam = undefined;
            state.mail = undefined;
            state.numPendingEvents = 0;
            state.numPendingUsers = 0;
        },
        csrf_token(state, token: string) {
            state.csrfToken = token;
        },
        select_team(state, selectedTeam: Team) {
            state.selectedTeam = selectedTeam;
        },
        num_pending_events(state, numNotifications: number) {
            state.numPendingEvents = numNotifications;
        },
        num_pending_users(state, numNotifications: number) {
            state.numPendingUsers = numNotifications;
        },
        dismissMetricsMessage(state, date: string) {
            state.metricsDismissedAt = date;
        },
        updateLastDateOfBirth(state, dateOfBirth: string) {
            state.lastDateOfBirth = dateOfBirth;
        },
    },
    actions: {
        register({commit}, mail: string) {
            return new Promise((resolve, reject) => {
                if (!mail) {
                    reject(new Error('mail is null or undefined'));
                }

                commit('register_success', mail);
                localStorage.setItem(KEY_MAIL, mail);

                resolve();
            });
        },
        login({commit}, user: User) {
            return new Promise((resolve, reject) => {
                if (!user) {
                    reject(new Error('user is null or undefined'));
                }

                commit('auth_success', user);
                commit('register_success', user.mail);
                localStorage.setItem(KEY_USER, JSON.stringify(user));
                localStorage.setItem(KEY_MAIL, user.mail);

                let selectedTeam: Team | undefined;
                let selectedTeamFromLocalStorage = getFromLocalStorage(KEY_SELECTED_TEAM);
                let firstTeam: Team | undefined;
                if (user) {
                    if (user.managedTeams && user.managedTeams.length > 0) {
                        firstTeam = user.managedTeams[0];
                        if (selectedTeamFromLocalStorage) {
                            selectedTeam = user.managedTeams.find(team => team.uid === selectedTeamFromLocalStorage.uid);
                        }
                    }
                    if (!selectedTeam && user.teams && user.teams.length > 0) {
                        firstTeam = !firstTeam ? user.teams[0] : firstTeam;
                        if (selectedTeamFromLocalStorage) {
                            selectedTeam = user.teams.find(team => team.uid === selectedTeamFromLocalStorage.uid);
                        }
                    }
                }
                if (!selectedTeam) {
                    selectedTeam = firstTeam;
                }
                commit('select_team', selectedTeam);
                if (selectedTeam) {
                    localStorage.setItem(KEY_SELECTED_TEAM, JSON.stringify(selectedTeam));
                } else {
                    localStorage.removeItem(KEY_SELECTED_TEAM);
                }
                resolve();
            });
        },
        logout({commit}) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(KEY_USER);

                if (this.state.selectedTeam) {
                    localStorage.setItem(KEY_SELECTED_TEAM, JSON.stringify({uid: this.state.selectedTeam.uid}));
                } else {
                    localStorage.removeItem(KEY_SELECTED_TEAM);
                }

                commit('logout');

                resolve();
            });
        },
        updateCsrfToken({commit}, token: string) {
            return new Promise((resolve, reject) => {
                // do not persist this token! only store it in-memory
                commit('csrf_token', token);
                resolve();
            });
        },
        selectTeam({commit}, selectedTeam: Team) {
            return new Promise((resolve, reject) => {
                commit('select_team', selectedTeam);
                if (selectedTeam) {
                    localStorage.setItem(KEY_SELECTED_TEAM, JSON.stringify(selectedTeam));
                } else {
                    localStorage.removeItem(KEY_SELECTED_TEAM);
                }
                resolve();
            });
        },
        numPendingEvents({commit}, numNotifications: number) {
            return new Promise((resolve, reject) => {
                commit('num_pending_events', numNotifications);
                resolve();
            });
        },
        numPendingUsers({commit}, numNotifications: number) {
            return new Promise((resolve, reject) => {
                commit('num_pending_users', numNotifications);
                resolve();
            });
        },
        deactivate({commit}) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(KEY_USER);
                localStorage.removeItem(KEY_SELECTED_TEAM);
                localStorage.removeItem(KEY_MAIL);
                localStorage.removeItem(KEY_METRICS_DISMISSED_AT);

                commit('deactivate');

                resolve();
            });
        },
        dismissMetricsMessage({commit}) {
            return new Promise((resolve, reject) => {
                const now = Vue.prototype.dayjs();
                const dateString = now.format("YYYY-MM-DDTHH:mm:ss");
                localStorage.setItem(KEY_METRICS_DISMISSED_AT, dateString);
                commit('dismissMetricsMessage', dateString);
                resolve();
            });
        },
        updateLastDateOfBirth({commit}, dateOfBirth: string) {
            return new Promise((resolve, reject) => {
                localStorage.setItem(KEY_LAST_DATE_OF_BIRTH, dateOfBirth);
                commit('updateLastDateOfBirth', dateOfBirth);
                resolve();
            });
        },
        updateLatestMetrics({commit}, metrics: Metrics) {
            return new Promise((resolve, reject) => {
                if (this.state.user) {
                    if (!this.state.user.latestMetrics) {
                        this.state.user.latestMetrics = metrics;
                    } else {
                        this.state.user.latestMetrics.dateTime = metrics.dateTime;
                        if (metrics.bodyHeightInCm) {
                            this.state.user.latestMetrics.bodyHeightInCm = metrics.bodyHeightInCm;
                        }
                        if (metrics.bodyWeightInKg) {
                            this.state.user.latestMetrics.bodyWeightInKg = metrics.bodyWeightInKg;
                        }
                    }
                    localStorage.setItem(KEY_USER, JSON.stringify(this.state.user));
                }
                resolve();
            });
        },
    },
    modules: {},
    getters: {
        isLoggedIn: state => state.user !== null && state.user !== undefined,
        user: state => state.user,
        mail: state => state.mail,
        latestMetrics: state => state.user ? state.user.latestMetrics : undefined,
        metricsDismissedAt: state => state.metricsDismissedAt,
        // roles: state => state.user ? state.user.roles : [],
        hasAdminRole: state => state.user && state.user.roles && state.user.roles.includes('admin'),
        hasCoachRole: state => state.user && state.user.roles && state.user.roles.includes('coach'),
        hasPlayerRole: state => state.user && state.user.roles && state.user.roles.includes('player'),
        managedTeams: state => state.user ? state.user.managedTeams : [],
        teams: state => state.user ? state.user.teams : [],
        selectedTeam: state => state.selectedTeam,
        teamUid: state => state.selectedTeam ? state.selectedTeam.uid : undefined,
        csrfToken: state => state.csrfToken,
        numNotifications: state => state.numPendingEvents + state.numPendingUsers,
        lastDateOfBirth: state => state.lastDateOfBirth,
        appVersion: state => state.appVersion,
    },
    plugins: [
        // createMultiTabState(),
    ],
});
