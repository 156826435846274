




































































































import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class StandardFormButtons extends Vue {
  @Prop({default: false})
  hasDelete!: boolean;

  @Prop({default: false})
  isSaving!: boolean;

  @Prop({default: false})
  isDeleting!: boolean;

  @Prop({default: false})
  isFormValid!: boolean;
}
