











import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import CategoriesComponent from "@/components/CategoriesComponent.vue";
import MetricsComponent from "@/components/MetricsComponent.vue";
import TimelineComponent from "@/components/TimelineComponent.vue";
import SizeAndWeightDiagram from "@/components/SizeAndWeightDiagram.vue";
import HappyBirthdayComponent from "@/components/HappyBirthdayComponent.vue";
import {LandingPage} from "@/types/app/landing-page";

@Component({
  components: {HappyBirthdayComponent, SizeAndWeightDiagram, TimelineComponent, MetricsComponent, CategoriesComponent}
})
export default class TheLandingPage extends mixins(Auth) {
  landingPage: LandingPage = null;

  // mounted() {
  //   // this.isLoading = true;
  //   // this.wasLoadingSuccessful = false;
  //   this.$http.get(`/api/landing-page`).then(response => {
  //     this.landingPage = response.data;
  //   }).then(() => {
  //     // this.wasLoadingSuccessful = true;
  //   }).finally(() => {
  //     // this.isLoading = false;
  //   });
  // }
};
