var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"dense":"","item-key":"name","items-per-page":20,"footer-props":{
          'items-per-page-options': [5, 10, 20, {'value': -1, 'text': _vm.$t('txt.all') }],
          'items-per-page-text': _vm.$t('txt.rowsPerPage'),
        },"no-data-text":_vm.$t('txt.noUsers')},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
        var item = ref.item;
return _vm._l((item.roles),function(role){return _c('v-chip',{key:role,staticClass:"mr-1",style:(role === 'admin' ? 'color: #ffffff;' : ''),attrs:{"x-small":"","color":role==='admin' ? '#002066' : (role === 'coach' ? '#ffc025' : '#ececec')}},[_vm._v(" "+_vm._s(_vm.$t('txt.role.' + role))+" ")])})}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'admin-user', params: {userUid: item.uid}}}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item.dateOfBirth",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.dateOfBirth))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }