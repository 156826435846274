

























































































import {Component, Watch} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class TheRegistrationPage extends Vue {
  isFormValid: boolean = false;
  mail: any = '';
  name: string = '';
  password: string = '';

  showPassword: boolean = false;
  showError: boolean = false;
  isRegistering: boolean = false;

  @Watch('$route.query.mail')
  onMailChanged() {
    this.mail = this.$route.query.mail;
  }

  created() {
    if (this.isLoggedIn) {
      this.$router.push({name: 'home'});
    }
    this.mail = this.$route.query.mail;
  }

  register() {
    this.isRegistering = true;
    this.$http.post('/auth/register', {
      name: this.name,
      mail: this.mail,
      password: this.password,
    }).then(() => {
      this.showError = false;
      this.$store.dispatch('register', this.mail).then(() => {
        this.$router.push({name: 'verify'});
      });
    }).catch(() => {
      this.showError = true;
    }).finally(() => {
      this.isRegistering = false;
    });
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }
};
