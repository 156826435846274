



























































































































import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import {Team, User} from "@/types/domain";
import TeamForm from "@/components/TeamForm.vue";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import {Date} from "@/mixins/date";
import PlayerForm from "@/components/PlayerForm.vue";
import TeamTable from "@/components/coach/TeamTable.vue";
import {Watch} from "vue-property-decorator";

@Component({
  components: {TeamTable, PlayerForm, StandardErrorCard, TeamForm}
})
export default class TheTeamPage extends mixins(Auth, Date) {
  team: Team | null = null;

  showTeamForm: boolean = false;
  showPlayerForm: boolean = false;
  addAsCoach: boolean = false;

  selectedTeamUid: string | null = null;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  isRemovingCoach: boolean = false;
  wasRemovingCoachSuccessful: boolean = false;

  mounted() {
    this.loadTeam();
  }

  @Watch('$store.getters.teamUid')
  onTeamSelectionChanged() {
    this.$router.push({name: 'coach-team-players', params: {teamUid: this.$store.getters.teamUid}});
  }

  @Watch('$route.params.teamUid')
  onTeamUidChanged() {
    this.loadTeam();
  }

  loadTeam() {
    const teamUid = this.$store.getters.teamUid;

    if (!teamUid) {
      return;
    }

    this.isLoading = true;
    this.wasLoadingSuccessful = false;

    return new Promise((resolve, reject) => {
      this.$http.get(`/api/teams/${teamUid}`).then(response => {
        this.team = response.data;
        this.wasLoadingSuccessful = !!(this.team && this.team.name);
        resolve(this.team);
      }).catch((reason) => {
        reject(reason);
      }).finally(() => {
        this.isLoading = false;
      });
    });
  }

  selectTeamAndShowTeamForm(teamUid: string | null) {
    this.selectedTeamUid = teamUid;
    this.showTeamForm = true;
  }

  selectTeamAndShowPlayerForm(teamUid: string | null, addAsCoach: boolean = false) {
    this.selectedTeamUid = teamUid;
    this.showPlayerForm = true;
    this.addAsCoach = addAsCoach;
  }

  onTeamsChanged() {
    this.setTeamFormClosed();

    this.$http.get('/api/users/current').then(result => {
      this.$store.dispatch('login', result.data);
    });

    this.loadTeam();
  }

  onPlayersChanged() {
    this.setPlayerFormClosed();

    this.loadTeam();
  }

  setTeamFormClosed() {
    this.showTeamForm = false;
  }

  setPlayerFormClosed() {
    this.showPlayerForm = false;
  }

  triggerRemoveCoach(user: User): void {
    this.removeCoach(user).then(() => {
      this.loadTeam();
    });
  }

  removeCoach(user: User) {
    this.isRemovingCoach = true;
    this.wasRemovingCoachSuccessful = false;

    const teamUid = this.$store.getters.selectedTeam.uid;
    return new Promise((resolve, reject) => {
      this.$http.post(`/api/teams/${teamUid}/remove-coaches`, {
        userUids: [user.uid]
      }).then(() => {
        this.wasRemovingCoachSuccessful = true;
        resolve();
      }).catch((reason) => {
        reject(reason);
      }).finally(() => {
        this.isRemovingCoach = false;
      });
    });
  }
};
