

























































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {EventStatistics, TrainingEvent, User} from "@/types/domain";
import {mixins} from "vue-class-component";
import {Time} from "@/mixins/time";
import {ImageMixin} from "@/mixins/image";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import MetricValue from "@/components/MetricValue.vue";
import {ModuleMixin} from "@/mixins/module";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import TimeSinceLastEventComponent from "@/components/TimeSinceLastEventComponent.vue";
import {Duration} from "@/mixins/duration";

@Component({
  components: {TimeSinceLastEventComponent, ConfirmationDialog, MetricValue, ErrorOverlay, CustomSkeletonLoader},
})
export default class TimelineComponent extends mixins(Time, ImageMixin, ModuleMixin, Duration) {
  @Prop({default: () => null})
  userUid: null | String;

  eventStatistics: null | EventStatistics = null;

  events: null | Array<TrainingEvent> = null;
  nextEvents: Array<TrainingEvent> = [];

  selectedCategoryUid: null | string = null;
  selectedIntensity: null | number = null;

  skip: number = 0;
  limit: number = 30;

  isLoadingStats: boolean = false;
  wasLoadingStatsSuccessful: boolean = false;

  isLoadingTimeline: boolean = false;
  wasLoadingSuccessful: boolean = true;

  // TODO type this
  regenerationStats = [];

  mounted() {
    this.loadStats();
    this.loadEvents().then(() => {
      this.events = this.nextEvents.splice(0, 15);
      this.limit = 15;
    });
    this.loadRegenerationStats();
  }

  @Watch('userUid')
  onUserUidChanged() {
    this.skip = 0;
    this.limit = 30;
    this.events = null;
    this.nextEvents = [];
    this.loadStats();
    this.loadEvents().then(() => {
      this.events = this.nextEvents.splice(0, 15);
      this.limit = 15;
    });
    this.loadRegenerationStats();
  }

  showNextEvents() {
    if (this.nextEvents && this.events) {
      this.events = this.events.concat(this.nextEvents);
      this.nextEvents = [];
      this.loadEvents();
    }
  }

  loadEvents() {
    return new Promise((resolve, reject) => {
      this.isLoadingTimeline = true;
      this.wasLoadingSuccessful = false;
      let url = `/api/events`;
      if (this.userUid) {
        url = `/api/users/${this.userUid}/events`;
      }
      const params = {
        skip: this.skip,
        limit: this.limit,
      };
      this.$http.get(url, {
        params
      }).then(response => {
        this.nextEvents = response.data;
      }).then(() => {
        this.wasLoadingSuccessful = true;
        this.skip += this.limit;
        resolve();
      }).finally(() => {
        this.isLoadingTimeline = false;
      });
    });
  }

  loadStats() {
    this.isLoadingStats = true;
    let url = `/api/users/stats`;
    if (this.userUid) {
      url = `/api/users/${this.userUid}/stats`;
    }

    this.$http.get(url).then(response => {
      this.eventStatistics = response.data;
    }).then(() => {
      this.wasLoadingStatsSuccessful = true;
    }).catch(() => {
      this.wasLoadingStatsSuccessful = false;
    }).finally(() => {
      this.isLoadingStats = false;
    });
  }

  loadRegenerationStats() {
    let url = `/api/users/current/regeneration-stats`;
    if (this.userUid) {
      url = `/api/users/${this.userUid}/regeneration-stats`;
    }

    this.$http.get(url).then(response => {
      this.regenerationStats = response.data;
      // this.regenerationStats.sort((a, b) => {});
    }).then(() => {

    }).catch(() => {

    }).finally(() => {

    });
  }

  async confirmToDeleteEvent(event: TrainingEvent) {
    const confirm: any = this.$refs.confirm;
    if (
        // TODO translate
        await confirm.open(
            "Wirklich löschen?",
            "Möchtest du die Trainingseinheit wirklich löschen?"
        )
    ) {
      this.deleteEvent(event);
    }
  }

  deleteEvent(event: TrainingEvent) {
    const userUid = this.userUid ? this.userUid : this.$store.getters.user.uid;
    this.$http.delete(`/api/users/${userUid}/events/${event.uid}`).then(response => {
      this.eventStatistics = response.data;
    }).then(() => {
      if (this.events) {
        // const position = this.events.indexOf(event);
        // console.debug('position', position);
        // this.events.slice(position, 1);
        this.events = this.events.filter(e => e.uid !== event.uid);
      }
      this.loadStats();
      this.loadRegenerationStats();
    }).catch(() => {
    }).finally(() => {
    });
  }

  getTextColor(intensity: number): string {
    if (intensity === 3) {
      return '#444444';
    }
    return '#ffffff';
  }

  getBackgroundColor(intensity: number): string {
    if (intensity === 1) {
      return '#39740a';
    } else if (intensity === 2) {
      return '#8bb2fc';
    } else if (intensity === 3) {
      return '#fbfc9f';
    } else if (intensity === 4) {
      return '#ffa563';
    }
    return '#c10000';
  }

  getDateString(dateString: string): string {
    try {
      const now = Vue.prototype.dayjs();
      const dateTimeOfTraining = Vue.prototype.dayjs(dateString);
      if (dateTimeOfTraining.format('YYYY-MM-DD') === now.format('YYYY-MM-DD')) {
        return this.$t('txt.today').toString();
      }
      if (dateTimeOfTraining.format('YYYY-MM-DD') === now.subtract(1, 'day').format('YYYY-MM-DD')) {
        return this.$t('txt.yesterday').toString();
      }
      if (dateTimeOfTraining.isBefore(now.subtract(1, 'week'))) {
        return dateTimeOfTraining.format('DD. MMMM');
      }
      return dateTimeOfTraining.format('dddd');
    } catch (e) {
      return '';
    }
  }

  selectCategory(categoryUid: string) {
    this.selectedCategoryUid = categoryUid;
    this.selectedIntensity = null;
  }

  selectIntensity(intensity: number) {
    this.selectedCategoryUid = null;
    this.selectedIntensity = intensity;
  }

  isSelected(event: TrainingEvent) {
    return event && event.module && event.module.category && (this.selectedCategoryUid === event.module.category.uid || this.selectedIntensity === event.module.intensity);
  }
}
