import Vue from 'vue';
import Component from 'vue-class-component';
import marked from 'marked';
import DOMPurify from 'dompurify';

@Component
export class Markdown extends Vue {
    dayjs = Vue.prototype.dayjs;

    getCompiledMarkdown(markdownText: string | null) {
        if (markdownText === null || markdownText === undefined) {
            return '';
        }

        const unsanitized = marked(markdownText);
        return DOMPurify.sanitize(unsanitized);
    }
}
