








































































































































































import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import ErrorOverlay from "@/components/ErrorOverlay.vue";

@Component({
  components: {ErrorOverlay},
})
export default class TheAccountPage extends Vue {
  panel: Array<any> = [];
  name: string = '';
  mail: string = '';

  isFormValid: boolean = true;
  isUpdatingAccount: boolean = false;
  showUpdateOkMessage: boolean = false;
  showUpdateErrorMessage: boolean = false;

  isPasswordFormValid: boolean = false;
  isChangingPassword: boolean = false;
  showPasswordOkMessage: boolean = false;
  showPasswordErrorMessage: boolean = false;
  showCurrentPassword: boolean = false;
  currentPassword: string = '';
  showNewPassword: boolean = false;
  newPassword: string = '';

  currentPasswordForDeleteAccount: string = '';
  isDeletingAccount: boolean = false;
  showDeleteErrorMessage: boolean = false;

  mounted() {
    if (this.$store.getters.user) {
      this.mail = this.$store.getters.user.mail;
      this.name = this.$store.getters.user.name;
    }

    // not necessary to load data; however, if the account was deactivated,
    // this returns 401 and the user will be locked out
    this.$http.get('/api/users/current');
  }

  updateAccount() {
    this.showUpdateOkMessage = false;
    this.showUpdateErrorMessage = false;
    this.isUpdatingAccount = true;
    this.$http.patch('/api/users/current', {name: this.name}).then(() => {
      this.showUpdateOkMessage = true;
    }).catch(() => {
      this.showUpdateErrorMessage = true;
    }).finally(() => {
      this.isUpdatingAccount = false;
    });
  }

  changePassword() {
    this.showPasswordOkMessage = false;
    this.showPasswordErrorMessage = false;
    this.isChangingPassword = true;
    this.$http.post('/api/users/current/change-password', {
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
    }).then(() => {
      this.changePasswordForm.reset();
      this.showPasswordOkMessage = true;
    }).catch(() => {
      this.showPasswordErrorMessage = true;
    }).finally(() => {
      this.isChangingPassword = false;
    });
  }

  deactivateAccount() {
    this.showDeleteErrorMessage = false;
    this.isDeletingAccount = true;
    this.$http.post('/api/users/current/deactivate', {
      mail: this.mail,
      password: this.currentPasswordForDeleteAccount
    }).then(() => {
      this.$store.dispatch('deactivate').finally(() => {
        this.$router.push({path: '/'});
      });
    }).catch(() => {
      this.showDeleteErrorMessage = true;
    }).finally(() => {
      this.isDeletingAccount = false;
    });
  }

  get changePasswordForm(): Vue & { reset: () => boolean } {
    return this.$refs.changePasswordForm as Vue & { reset: () => boolean };
  }
}
