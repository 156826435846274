












































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Category, Image, Team} from "@/types/domain";
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";
import ModuleHeader from "@/components/ModuleHeader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import {Duration} from "@/mixins/duration";

@Component({
  components: {StandardFormButtons, CustomSkeletonLoader, StandardFormToolbar, ErrorOverlay, ModuleHeader},
})
export default class CategoryForm extends mixins(ImageMixin, AxiosMixin, Duration) {
  @Prop({default: false})
  show: boolean;

  @Prop({default: null})
  categoryUid: string | null;

  @Prop({default: null})
  teamUid: string | null;

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  teams: Array<Team> = [];
  selectedTeam: Team | null = null;

  showDialog: boolean = false;
  valid: boolean = false;
  select: string | null = null;

  name: string = '';
  imageFile: any = null; // TODO restrict to specific size
  existingImages: Array<Image> = [];
  selectedImageUid: string | null = null;

  isRegenerationActive: boolean = false;
  regenerationInHours: number | null = null;

  cancelTokenSource: CancelTokenSource | undefined;

  @Watch('showSkeletonLoader')
  onShowSkeletonLoaderChanged() {
    if (!this.showSkeletonLoader) {
      this.resetForm();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadCategory();
      this.loadImages();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  @Watch('isRegenerationActive')
  onIsRegenerationActiveChanged() {
    if (!this.isRegenerationActive) {
      this.regenerationInHours = null;
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  loadCategory() {
    this.imageFile = null;

    if (!this.isEditForm) {
      this.name = '';
      this.selectedImageUid = null;
      this.regenerationInHours = null;
      this.isLoading = false;
      this.wasLoadingSuccessful = true;
      return;
    }

    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;

    let url: string;
    if (this.teamUid) {
      url = `/api/teams/${this.teamUid}/categories/${this.categoryUid}`;
    } else {
      url = `/api/categories/${this.categoryUid}`;
    }

    this.$http.get(url, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      const category: Category = response.data;
      this.name = category.name;
      this.selectedImageUid = category.image ? category.image.uid : null;

      if (category.regeneration !== null && category.regeneration !== undefined) {
        this.isRegenerationActive = true;
        this.regenerationInHours = this.toMinutes(category.regeneration) / 60;
      }
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  uploadImageFile() {
    if (!this.imageFile) {
      return;
    }

    let formData = new FormData();

    formData.append("file", this.imageFile);
    formData.append("tag", this.name);

    this.$http.post("/api/images", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response => {
      this.existingImages.push(response.data);
      this.selectedImageUid = response.data.uid;
    });
  }

  save() {
    if (this.isEditForm) {
      this.update();
    } else {
      this.createNew();
    }
  }

  createNew() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.post("/api/categories", {
      teamUid: this.teamUid ? this.teamUid : undefined,
      imageUid: this.selectedImageUid,
      name: this.name,
      regenerationInHours: this.isRegenerationActive ? this.regenerationInHours : undefined,
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then((response) => {
      this.$emit('submit', response.data);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  update() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.put(`/api/categories/${this.categoryUid}`, {
      imageUid: this.selectedImageUid,
      name: this.name,
      regenerationInHours: this.isRegenerationActive ? this.regenerationInHours : undefined,
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then((response) => {
      this.$emit('submit', response.data);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  deleteCategory() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isDeleting = true;
    this.$http.delete(`/api/categories/${this.categoryUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('delete', this.categoryUid);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isDeleting = false;
    });
  }

  loadImages() {
    this.$http.get('/api/images').then(response => {
      this.existingImages = response.data;
    });
  }

  selectImage(image: Image) {
    if (!this.isDisabled) {
      this.selectedImageUid = image.uid;
    }
  }

  closeDialog() {
    this.$emit('cancel');
  }

  get selectedImage() {
    return this.existingImages.find(image => image.uid === this.selectedImageUid);
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get selectedTeamName() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.name : '';
  }

  get isEditForm() {
    return this.categoryUid && this.categoryUid !== '';
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }
}
