











































import {mixins} from 'vue-class-component';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {Team} from "@/types/domain";
import TeamTable from "@/components/coach/TeamTable.vue";

@Component({
  components: {TeamTable}
})
export default class TeamPanels extends mixins(Date) {
  @Prop({default: []})
  teams!: Array<Team>;

  panel: Array<number> = [];

  @Watch('teams')
  onTeamsChanged() {
    this.updatePanel();
  }

  updatePanel() {
    const selectedTeamUid = this.$store.getters.selectedTeam.uid;

    for (let i = 0; i < this.teams.length; i++) {
      if (this.teams[i].uid === selectedTeamUid) {
        this.panel.push(i);
      }
    }
  }

  get isCoach() {
    return this.$store.getters.hasCoachRole;
  }
};
