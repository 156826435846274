



























import Component, {mixins} from 'vue-class-component';
import {User} from "@/types/domain";
import PlayerData from "@/components/coach/PlayerData.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import StandardErrorCard from "@/components/StandardErrorCard.vue";

@Component({
  components: {StandardErrorCard, CustomSkeletonLoader, PlayerData}
})
export default class ThePlayerPage extends mixins() {
  isLoadingUser: boolean = false;
  wasLoadingSuccessful: boolean = false;

  showUpdateOkMessage: boolean = false;
  showUpdateErrorMessage: boolean = false;

  isRemovingFromTeam: boolean = false;
  showRemoveErrorMessage: boolean = false;

  user: User | null = null;
  dateOfBirth: any = null;

  mounted() {
    this.loadUser();
  }

  loadUser() {
    this.isLoadingUser = true;
    this.wasLoadingSuccessful = false;

    const uid = this.$route.params.userUid;
    return new Promise((resolve, reject) => {
      this.$http.get(`/api/users/${uid}`).then(response => {
        this.user = response.data;
        this.wasLoadingSuccessful = true;
        resolve(this.user);
      }).catch((reason) => {
        reject(reason);
      }).finally(() => {
        this.isLoadingUser = false;
      });
    });
  }

  routerNavigation() {
    if (this.$route.name === 'coach-team-player') {
      this.$router.push({name: 'coach-team-players', params: this.$route.params});
    } else {
      this.$router.push({name: 'admin-users', params: this.$route.params});
    }
  }
}
