
































































































































































































import Component, {mixins} from 'vue-class-component';
import {ArrayMixin} from "@/mixins/arrayMixin";
import TrainingScheduleForm from "@/components/TrainingScheduleForm.vue";
import {Calendar, TrainingSchedule} from "@/types/domain";
import {Date} from "@/mixins/date";
import {Markdown} from "@/mixins/markdown";
import {Watch} from "vue-property-decorator";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {Recurrence} from "@/mixins/recurrence";
import CalendarWeekView from "@/components/trainingSchedule/CalendarWeekView.vue";
import {Dayjs} from "dayjs";

@Component({
  components: {CalendarWeekView, CustomSkeletonLoader, TrainingScheduleForm}
})
export default class TheCoachTrainingSchedulePage extends mixins(ArrayMixin, Date, Markdown, Recurrence) {
  showTrainingScheduleForm: boolean = false;

  isLoading: boolean = false;

  selectedTrainingScheduleUid: string = null;
  trainingSchedules: Array<TrainingSchedule> = null;
  calendar: Calendar = null;
  startOfCalendarView: Dayjs = this.dayjs().weekday(0);

  view: number = 0;
  show: number = 0;
  includeInactive: number = 0;

  mounted() {
    if (this.$route.query.start) {
      try {
        this.startOfCalendarView = this.dayjs(this.$route.query.start);
      } catch (e) {
      }
    }
    if (this.$route.query.includeInactive === 'true') {
      this.includeInactive = 1;
    } else {
      this.includeInactive = 0;
    }

    this.reloadView();
    this.reloadShow();
  }

  reloadView() {
    if (this.$route.query.view === 'calendar') {
      this.view = 1;
      this.loadCalendar();
    } else {
      this.view = 0;
      this.loadTrainingSchedules();
    }
  }

  reloadShow() {
    if (this.$route.query.show === 'players') {
      this.show = 1;
    } else {
      this.show = 0;
    }
  }

  @Watch('$store.getters.teamUid')
  onTeamSelectionChanged() {
    this.$router.push({params: {teamUid: this.$store.getters.teamUid}});
  }

  @Watch('$route.params.teamUid')
  onTeamUidChanged() {
    this.reloadView();
  }

  @Watch('$route.query.view')
  onViewChanged() {
    // console.debug('view changed');
    this.reloadView();
  }

  @Watch('$route.query.start')
  onStartChanged() {
    // console.debug('start changed');
    if (this.$route.query.start) {
      this.startOfCalendarView = this.dayjs(this.$route.query.start);
    }
    this.loadCalendar();
  }

  @Watch('$route.query.includeInactive')
  onIncludeInactiveChanged() {
    if (this.$route.query.includeInactive === 'true') {
      this.includeInactive = 1;
    } else {
      this.includeInactive = 0;
    }
    this.loadTrainingSchedules();
  }

  @Watch('$route.query.show')
  onShowChanged() {
    this.reloadShow();
  }

  showTeam() {
    // const query = {};
    // console.debug('this.$route', this.$route);
    // const a = Object.assign(query, this.$route.query);
    // console.debug('new query', a);
    // this.$router.push({query: Object.assign(a, {show: 'team'})});
    this.push({show: 'team'});
  }

  showPlayers() {
    this.push({show: 'players'});
  }

  onTrainingScheduleChanged() {
    this.showTrainingScheduleForm = false;
    this.loadTrainingSchedules();
  }

  loadTrainingSchedules() {
    this.isLoading = true;
    this.trainingSchedules = null;

    const params = {
      includeInactive: this.includeInactive === 1,
    };
    this.$http.get(`/api/teams/${this.$store.getters.teamUid}/training-schedules`, {params}).then(response => {
      this.trainingSchedules = response.data;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  // TODO use service and re-use in views/TheTrainingSchedulePage (?)
  loadCalendar() {
    const params = {
      start: this.startOfCalendarView.format('YYYY-MM-DD'),
      end: this.startOfCalendarView.add(7, 'day').format('YYYY-MM-DD'),
    };
    this.$http.get(`/api/teams/${this.$store.getters.teamUid}/calendar`, {params}).then(response => {
      this.calendar = response.data;

      this.calendar.trainingSchedules.forEach(schedule => {
        const map = new Map();
        schedule.events.forEach(event => {
          let entry = map.get(event.dateIsoString);
          if (!entry) {
            entry = [];
          }
          entry.push(event);
          map.set(event.dateIsoString, entry);
        });
        schedule.eventsMap = map;
      });
    });
  }

  push(queryObject) {
    const query = {};
    const a = Object.assign(query, this.$route.query);
    this.$router.push({query: Object.assign(a, queryObject)});
  }

  loadPreviousView() {
    this.push({start: this.startOfCalendarView.subtract(7, 'day').format('YYYY-MM-DD')});
    // this.$router.push({query: {start: this.startOfCalendarView.subtract(7, 'day').format('YYYY-MM-DD')}});
  }

  loadNextView() {
    this.push({start: this.startOfCalendarView.add(7, 'day').format('YYYY-MM-DD')});
  }

  loadToday() {
    this.push({start: this.dayjs().weekday(0).format('YYYY-MM-DD')});
  }

  addTrainingSchedule() {
    this.selectedTrainingScheduleUid = null;
    this.showTrainingScheduleForm = true;
  }

  editTrainingSchedule(uid: string) {
    this.selectedTrainingScheduleUid = uid;
    this.showTrainingScheduleForm = true;
  }

  getAvailableRecurrences(): object {
    return {
      'ONCE': this.$t('txt.recurrence.ONCE').toString(),
      'DAILY': this.$t('txt.recurrence.daily').toString(),
      'WEEKLY': this.$t('txt.recurrence.weekly').toString(),
      'MONTHLY': this.$t('txt.recurrence.monthly').toString(),
      'YEARLY': this.$t('txt.recurrence.yearly').toString(),
    };
  }

  get filteredTrainingSchedules(): Array<TrainingSchedule> {
    if (this.trainingSchedules) {
      // return this.trainingSchedules.filter(schedule => (this.view === 0) === (schedule.teams && schedule.teams.length > 0));
      if (this.show === 0) {
        return this.trainingSchedules.filter(schedule => schedule.teams && schedule.teams.length > 0);
      } else {
        return this.trainingSchedules.filter(schedule => !schedule.teams || schedule.teams.length === 0);
      }
    }
    return [];
  }

  // TODO duplicated code fragment
  get modulesMap() {
    const map = new Map();

    if (this.calendar && this.calendar.modules) {
      this.calendar.modules.forEach(module => {
        map.set(module.uid, module);
      });
    }
    return map;
  }

  // TODO duplicated code fragment
  get eventsMap() {
    const map = new Map();

    if (this.calendar && this.calendar.completedEventsWithoutSchedule) {
      this.calendar.completedEventsWithoutSchedule.forEach(event => {
        let entry = map.get(event.dateIsoString);
        if (!entry) {
          entry = [];
        }
        entry.push(event.moduleUid);
        map.set(event.dateIsoString, entry);
      });
    }
    return map;
  }

  get calendarViewTitle() {
    const start = this.startOfCalendarView;
    const startMonth = start.format('MMM');
    const startYear = start.format('YYYY');
    const end = start.add(7, 'day');
    const endMonth = end.format('MMM');
    const endYear = end.format('YYYY');

    return startMonth + (startYear !== endYear ? ' ' + startYear : '') + (startMonth !== endMonth ? ' - ' + endMonth : '') + ' ' + endYear;
  }
};
