





































import {mixins} from 'vue-class-component';
import {Component, Watch} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {TrainingEvent, User} from "@/types/domain";
import EventsTable from "@/components/coach/EventsTable.vue";
import EnrollmentsTable from "@/components/coach/EnrollmentsTable.vue";
import {EventApprovalInput, PendingEvent, PendingUser, UserApprovalInput} from "@/types/app";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

@Component({
  components: {ConfirmationDialog, StandardErrorCard, AdminEnrollmentsTable: EnrollmentsTable, AdminEventsTable: EventsTable}
})
export default class TheNotificationsPage extends mixins(Date) {
  pendingUsers: Array<User> = [];
  pendingEvents: Array<TrainingEvent> = [];

  team: any = null;

  isLoadingPendingUsers: boolean = false;
  wasLoadingPendingUsersSuccessful: boolean = false;
  isLoadingEvents: boolean = false;
  wasLoadingEventsSuccessful: boolean = false;

  mounted() {
    this.loadPendingUsersAndEvents();
  }

  @Watch('$store.getters.numNotifications')
  onNumNotificationsChanged(currentNum: number, previousNum: number) {
    if (currentNum > previousNum) {
      this.loadPendingUsersAndEvents();
    }
  }

  loadPendingUsersAndEvents() {
    this.loadPendingUsers();
    this.loadEvents();
  }

  loadPendingUsers() {
    this.wasLoadingPendingUsersSuccessful = false;
    this.isLoadingPendingUsers = true;
    this.$http.get(`/api/users/pending`).then(response => {
      this.pendingUsers = response.data;
      this.wasLoadingPendingUsersSuccessful = true;
    }).then(() => this.setHasPendingUsers()).finally(() => {
      this.isLoadingPendingUsers = false;
    });
  }

  loadEvents() {
    this.wasLoadingEventsSuccessful = false;
    this.isLoadingEvents = true;
    this.$http.get(`/api/users/events`).then(response => {
      this.pendingEvents = response.data;
      this.wasLoadingEventsSuccessful = true;
    }).then(() => this.setHasPendingEvents()).finally(() => {
      this.isLoadingEvents = false;
    });
  }

  setHasPendingUsers() {
    this.$store.dispatch('numPendingUsers', this.pendingUsers.length);
  }

  setHasPendingEvents() {
    this.$store.dispatch('numPendingEvents', this.pendingEvents.length);
  }

  approvePendingUsers(users: Array<PendingUser>) {
    if (!users) {
      return;
    }

    const approvalInput: Array<UserApprovalInput> = [];

    users.forEach(user => {
      const selectedTeamUid = user.selectedTeam ? user.selectedTeam.uid : undefined;
      if (selectedTeamUid) {
        approvalInput.push({
          userUid: user.uid,
          teamUid: selectedTeamUid,
          isPlayer: user.isPlayer,
          isCoach: user.isCoach
        });
      }
    });
    this.$http.post(`/api/users/approve`, approvalInput).then(response => {
      this.pendingUsers = response.data;
    }).then(() => this.setHasPendingUsers());
  }

  approveEvents(events: Array<PendingEvent>) {
    if (!events) {
      return;
    }

    const approvalInput: Array<EventApprovalInput> = [];

    events.forEach(event => {
      approvalInput.push({
        eventUid: event.uid,
      });
    });
    this.$http.post(`/api/events/approve`, approvalInput).then(response => {
      this.pendingEvents = response.data;
    }).then(() => this.setHasPendingEvents());
  }

  async confirmToDeletePendingUsers(users: Array<PendingUser>) {
    if (!users) {
      return;
    }

    const confirm: any = this.$refs.confirm;
    if (
        await confirm.open(
            this.$t('confirm.delete'),
            this.$tc('confirm.delete.pendingUsers', users.length, {numUsers: users.length})
        )
    ) {
      this.deletePendingUsers(users);
    }
  }

  deletePendingUsers(users: Array<PendingUser>) {
    if (!users) {
      return;
    }

    this.$http.post(`/api/users/decline`, users.map(user => user.uid)).then(response => {
      this.pendingUsers = response.data;
    }).then(() => this.setHasPendingUsers());
  }

  async confirmToDeleteEvents(events: Array<PendingEvent>) {
    if (!events) {
      return;
    }

    const confirm: any = this.$refs.confirm;
    if (
        await confirm.open(
            this.$t('confirm.delete'),
            this.$tc('confirm.delete.events', events.length, {numEvents: events.length})
        )
    ) {
      this.deleteEvents(events);
    }
  }

  deleteEvents(events: Array<PendingEvent>) {
    if (!events) {
      return;
    }

    this.$http.post(`/api/events/decline`, events.map(event => event.uid)).then(response => {
      this.pendingEvents = response.data;
    }).then(() => this.setHasPendingEvents());
  }

  get isLoading() {
    return this.isLoadingPendingUsers || this.isLoadingEvents;
  }

  get hasNotifications() {
    return this.$store.getters.numNotifications > 0;
  }

  get wasLoadingSuccessful() {
    return this.wasLoadingPendingUsersSuccessful && this.wasLoadingEventsSuccessful;
  }
};
