

















import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class StandardErrorCard extends Vue {
  @Prop({default: false})
  show!: boolean;
}
