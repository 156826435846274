import Vue from 'vue';
import Component from 'vue-class-component';
import {Dayjs} from "dayjs";

@Component
export class Date extends Vue {
    dayjs = Vue.prototype.dayjs;

    formatTime(dateString: string) {
        if (!dateString) {
            return '';
        }

        const date = this.dayjs(dateString);
        const now = this.dayjs();

        if (date.isBefore(now.subtract(11, 'month'))) {
            return this.dayjs(dateString).format('DD. MMM YYYY');
        }
        if (date.isBefore(now.subtract(18, 'hour'))) {
            return this.dayjs(dateString).format('DD. MMM');
        }
        return this.dayjs(dateString).format('HH:mm');
    }

    formatDate(dateString: string) {
        if (!dateString) {
            return '';
        }

        // const date = this.dayjs(dateString);
        // const now = this.dayjs();
        //
        // if (date.isBefore(now.subtract(11, 'month'))) {
        //     return this.dayjs(dateString).format('DD. MMM YYYY');
        // }
        return this.dayjs(dateString).format('DD. MMM YYYY');
    }

    formatTrainingDayAndTime(dateString: string) {
        if (!dateString) {
            return '';
        }
        return this.dayjs(dateString).format('DD. MMM HH:mm');
    }

    formatDateForDatePicker(date: Dayjs): string {
        if (!date) {
            return '';
        }

        return date.format('DD. MMM YYYY');
    }
}
