



































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Module, ModuleWithConfig, RecurrenceConfig, Team, TrainingSchedule, User} from "@/types/domain";
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";
import ModuleHeader from "@/components/ModuleHeader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import {Markdown} from "@/mixins/markdown";
import Vue from "vue";
import {Date} from "@/mixins/date";
import {RecurrenceConfigFormInput, TrainingScheduleFormInput} from "@/types/app/forms";
import {Duration} from "@/mixins/duration";
import ModuleRecurrenceForm from "@/components/coach/ModuleRecurrenceForm.vue";

@Component({
  components: {
    ModuleRecurrenceForm, StandardFormButtons, CustomSkeletonLoader, StandardFormToolbar, ErrorOverlay, ModuleHeader
  },
})
export default class TrainingScheduleForm extends mixins(Markdown, ImageMixin, AxiosMixin, Date, Duration) {
  @Prop({default: false})
  show: boolean;

  @Prop({default: null})
  trainingScheduleUid: string;

  @Prop({default: null})
  team: Team;

  isLoadingFormData: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  showDialog: boolean = false;
  isFormValid: boolean = false;

  usersSearchInput: string = '';

  showCoreProps: boolean = true;

  trainingSchedule: TrainingSchedule = null;

  isForEntireTeam: boolean = true;
  availablePlayers: Array<User> = null;

  moduleToAdd: any = {};
  modules: Array<Module>;

  cancelTokenSource: CancelTokenSource | undefined;

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadFormData();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  @Watch('isForEntireTeam')
  onIsForEntireTeamChanged() {
    if (this.trainingSchedule) {
      if (this.isForEntireTeam) {
        this.trainingSchedule.teams = this.getInitialTrainingSchedule().teams;
      } else {
        this.trainingSchedule.teams = [];
      }
    }
  }

  loadFormData() {
    let url = '';
    if (this.isEditForm) {
      url = `/api/training-schedules/${this.trainingScheduleUid}/form-data`;
    } else {
      url = `/api/training-schedules/form-data`;
      this.trainingSchedule = this.getInitialTrainingSchedule();
    }

    const params = {
      teamUid: this.team.uid,
    };

    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoadingFormData = true;
    this.$http.get(url, {
      params,
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      if (response.data.trainingSchedule) {
        this.trainingSchedule = response.data.trainingSchedule;
        this.isForEntireTeam = !this.trainingSchedule.users || this.trainingSchedule.users.length === 0;
      }
      if (response.data.modules) {
        this.modules = response.data.modules;
      }
      if (response.data.players) {
        this.availablePlayers = response.data.players;
      }
    }).finally(() => {
      this.isLoadingFormData = false;
    });
  }

  // updateDurationHint() {
  //   if (this.start && this.end) {
  //     const params = {
  //       start: this.start,
  //       end: this.end,
  //     };
  //
  //     this.$http.get(`/api/training-schedules/period`, {params}).then(response => {
  //       const years = response.data.years;
  //       const months = response.data.months;
  //       const days = response.data.days;
  //
  //       let durationString = this.$t('txt.duration') + ':';
  //
  //       durationString += years && years > 0 ? ' ' + years + ' ' + this.$tc('txt.unit.year', years) : '';
  //       durationString += months && months > 0 ? ' ' + months + ' ' + this.$tc('txt.unit.month', months) : '';
  //       durationString += days && days > 0 ? ' ' + days + ' ' + this.$tc('txt.unit.day', days) : '';
  //       this.durationHint = durationString;
  //     });
  //   }
  // }

  static fromTrainingSchedule(trainingSchedule: TrainingSchedule): TrainingScheduleFormInput {
    return {
      title: trainingSchedule.title,
      description: trainingSchedule.description,
      teamUids: trainingSchedule.teams.map(team => team.uid),
      userUids: !trainingSchedule.teams || trainingSchedule.teams.length === 0 ? trainingSchedule.users.map(user => user.uid) : [],
      moduleConfigs: trainingSchedule.modules.map(module => TrainingScheduleForm.fromRecurrenceConfig(module.recurrenceConfig)),
    };
  }

  static fromRecurrenceConfig(recurrenceConfig: RecurrenceConfig): RecurrenceConfigFormInput {
    if (recurrenceConfig.frequency === 'ONCE') {
      return {
        moduleUid: recurrenceConfig.moduleUid,
        startDate: recurrenceConfig.startDate,

        frequency: recurrenceConfig.frequency,
        interval: undefined,
        numberOfRepeats: undefined,

        weekdays: undefined,
        monthdays: undefined,
      };
    }

    return {
      moduleUid: recurrenceConfig.moduleUid,
      startDate: recurrenceConfig.startDate,

      frequency: recurrenceConfig.frequency,
      interval: recurrenceConfig.hasInterval ? recurrenceConfig.interval : undefined,
      numberOfRepeats: recurrenceConfig.isEnding ? recurrenceConfig.numberOfRepeats : undefined,

      weekdays: recurrenceConfig.weekdays,
      monthdays: recurrenceConfig.monthdays,
    };
  }

  getInitialTrainingSchedule(): TrainingSchedule {
    return {
      uid: '',

      title: '',
      description: '',

      startDateIsoString: '',
      endDateIsoString: '',

      teams: [this.team],
      users: [],
      modules: [],
      events: [],
    };
  }

  // getInitialTrainingSchedule(): TrainingScheduleFormInput {
  //   return {
  //     title: '',
  //     description: '',
  //
  //     teamUids: [this.team.uid],
  //     userUids: [],
  //     moduleConfigs: [],
  //   };
  // }

  resetForm() {
    this.showCoreProps = true;
    if (this.isEditForm) {
      this.trainingSchedule = null;
    } else {
      this.trainingSchedule = this.getInitialTrainingSchedule();
    }

    this.isForEntireTeam = true;
    this.availablePlayers = null;
    this.modules = [];

    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.resetValidation();
    }
  }

  save() {
    if (this.isEditForm) {
      this.update();
    } else {
      this.createNew();
    }
  }

  createNew() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;

    this.$http.post("/api/training-schedules", TrainingScheduleForm.fromTrainingSchedule(this.trainingSchedule), {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  update() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;

    this.$http.put(`/api/training-schedules/${this.trainingScheduleUid}`, TrainingScheduleForm.fromTrainingSchedule(this.trainingSchedule), {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  deleteTrainingSchedule() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isDeleting = true;
    this.$http.delete(`/api/training-schedules/${this.trainingScheduleUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isDeleting = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  moduleSelectionFilter(module: Module, queryText: string, itemText) {
    if (!queryText) {
      return true;
    }
    const query = queryText.toLowerCase();

    const name = module.name ? module.name.toLowerCase() : "";
    if (name.indexOf(query) > -1) {
      return true;
    }

    const instructions = module.instructions ? module.instructions.toLowerCase() : "";
    if (instructions.indexOf(query) > -1) {
      return true;
    }

    const intensity = module.intensity ? String(module.intensity).toLowerCase() : "";
    if (intensity.indexOf(query) > -1) {
      return true;
    }

    const categoryName = module.category && module.category.name ? module.category.name.toLowerCase() : "";
    return categoryName.indexOf(query) > -1;
  }

  static getInitialRecurrenceConfig(module: Module | null = null): RecurrenceConfig {
    return {
      moduleUid: module ? module.uid : undefined,
      startDate: Vue.prototype.dayjs().format('YYYY-MM-DD'),
      endDate: null,
      frequency: 'ONCE',
      hasInterval: false,
      interval: 2,
      isEnding: true,
      numberOfRepeats: 5,
      weekdays: [],
      monthdays: [],
    };
  }

  addModuleToTrainingSchedule(module: Module) {
    this.moduleToAdd = {};

    const recurrenceConfig: RecurrenceConfig = TrainingScheduleForm.getInitialRecurrenceConfig(module);
    const moduleWithConfig: ModuleWithConfig = {
      ...module,
      recurrenceConfig,
    };

    this.trainingSchedule.modules.unshift(moduleWithConfig);
    this.showCoreProps = false;
  }

  removeModuleFromTrainingSchedule(index: number) {
    if (!this.trainingSchedule || !this.trainingSchedule.modules || index === null) {
      return;
    }

    this.trainingSchedule.modules = this.trainingSchedule.modules.filter((m, i) => i !== index);
  }

  closeUsers() {
    this.$nextTick(() => {
      const selectElement: any = this.$refs.users;
      if (selectElement) {
        selectElement.isMenuActive = false;
      }
    });
  }

  filterUsers(item: User, queryText: string, itemText: string): boolean {
    if (itemText.toLowerCase().includes(queryText.toLowerCase())) {
      return true;
    }

    return !!(item.mail && item.mail.includes(queryText));
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get isEditForm() {
    return this.trainingScheduleUid && this.trainingScheduleUid !== '';
  }

  get durationText() {
    return 'TODO';
    // if (!this.trainingSchedule || !this.trainingSchedule.duration || !this.trainingSchedule.duration.value ||
    //     this.trainingSchedule.duration.value <= 0 || !this.trainingSchedule.duration.unitToken) {
    //   return '';
    // }
    // return this.$t('txt.duration') + ': ' + this.trainingSchedule.duration.value + ' ' +
    //     this.$tc('txt.unit.' + this.trainingSchedule.duration.unitToken, this.trainingSchedule.duration.value);
  }
}
