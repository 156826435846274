

























































































import Component, {mixins} from 'vue-class-component';
import {User} from "@/types/domain";
import {Date} from "@/mixins/date";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import {Prop} from "vue-property-decorator";
import PositionsCard from "@/components/coach/PositionCard.vue";
import TeamMembershipCard from "@/components/coach/TeamMembershipCard.vue";
import UserDataCard from "@/components/coach/UserDataCard.vue";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";

@Component({
  components: {ConfirmationDialog, UserDataCard, TeamMembershipCard, PositionsCard, ErrorOverlay}
})
export default class PlayerData extends mixins(Date) {
  @Prop({default: null})
  user!: User | null;

  accountPanel: Array<number> = [];

  showDeleteErrorMessage: boolean = false;
  isDeletingAccount: boolean = false;

  async confirmToDeleteAccount(user: User) {
    const confirm: any = this.$refs.confirm;
    if (
        // TODO translate
        await confirm.open(
            "Account endgültig löschen?",
            `Möchtest du den Account von '${user.name}' wirklich endgültig löschen? -
            Die Änderung wird sofort wirksam und kann nicht rückgängig gemacht werden.`
        )
    ) {
      this.deleteAccount(user.uid);
    }
  }

  deactivateAccount(userUid: string) {
    this.showDeleteErrorMessage = false;
    this.isDeletingAccount = true;
    this.$http.post(`/api/users/${userUid}/deactivate`).then(() => {
      this.$emit('update', userUid);
    }).catch(() => {
      this.showDeleteErrorMessage = true;
    }).finally(() => {
      this.isDeletingAccount = false;
    });
  }

  deleteAccount(userUid: string) {
    this.showDeleteErrorMessage = false;
    this.isDeletingAccount = true;
    this.$http.delete(`/api/users/${userUid}`).then(() => {
      this.$emit('deleted', userUid);
    }).catch(() => {
      this.showDeleteErrorMessage = true;
    }).finally(() => {
      this.isDeletingAccount = false;
    });
  }

  activateAccount(userUid: string) {
    this.showDeleteErrorMessage = false;
    this.isDeletingAccount = true;
    this.$http.post(`/api/users/${userUid}/activate`).then(() => {
      this.$emit('update', userUid);
    }).catch(() => {
      this.showDeleteErrorMessage = true;
    }).finally(() => {
      this.isDeletingAccount = false;
    });
  }
};
