import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class Time extends Vue {
    convertToMinutes(seconds: number): (number | string) {
        if (seconds) {
            return seconds / 60;
        }
        return '';
    }
}
