import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from '@/axios';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import VueCookies from 'vue-cookies';

import lodash from 'lodash';

import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';
// import updateLocal from 'dayjs/plugin/updateLocale';
// import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';

Vue.prototype.lodash = lodash;

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
// dayjs.extend(updateLocal);
// dayjs.extend(isBetween);
dayjs.extend(utc);

// dayjs.locale(process.env.VUE_APP_I18N_LOCALE);
// dayjs.locale({
//     name: 'de',
//     weekStart: 1,
//     formats: {
//         LTS: 'h:mm:ss A',
//         LT: 'h:mm A',
//         L: 'YYYY-MM-DD',
//         LL: 'MMMM D, YYYY', // TODO
//         LLL: 'MMMM D, YYYY h:mm A',
//         LLLL: 'dddd, MMMM D, YYYY h:mm A',
//     },
//     relativeTime: {
//         future: 'in %s',
//         past: '%s ago',
//         s: 'a few seconds',
//         m: 'a minute',
//         mm: '%d minutes',
//         h: 'an hour',
//         hh: '%d hours', // e.g. 2 hours, %d been replaced with 2
//         d: 'a day',
//         dd: '%d days',
//         M: 'a month',
//         MM: '%d months',
//         y: 'a year',
//         yy: '%d years'
//     },
// }, null, true);
dayjs.locale('de');
Vue.prototype.dayjs = dayjs;

Vue.use(VueAxios, axios);
Vue.use(VueCookies);

Vue.use(VueI18n);
Vue.config.productionTip = false;
const messages = {
    'de': require('./i18n/messages.de.json'),
    'en': require('./i18n/messages.en.json'),
};

const i18n = new VueI18n({
    locale: navigator.language.split('-')[0].toLowerCase() || 'de',
    fallbackLocale: 'de',
    messages,
});
axios.defaults.headers.common['Accept-Language'] = i18n.locale;

Vue.prototype.$t = function (t: any, p: any) {
    return i18n.t(t, p);
};
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app');
