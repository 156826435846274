




























































































































































































import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";
import {EventForCalendar, Module, ModuleForCalendar, TrainingScheduleEvent, TrainingScheduleForCalendar} from "@/types/domain";
import {Date} from "@/mixins/date";
import {Dayjs} from "dayjs";
import ModuleCompletedForm from "@/components/ModuleCompletedForm.vue";
import {Markdown} from "@/mixins/markdown";

@Component({
  components: {ModuleCompletedForm},
})
export default class CalendarWeekView extends mixins(ImageMixin, Date, Markdown) {
  @Prop({default: () => []})
  trainingSchedules: Array<TrainingScheduleForCalendar>;

  @Prop({default: () => []})
  completedEventsWithoutSchedule: Array<EventForCalendar>;

  @Prop({default: () => null})
  modulesMap: Map<string, ModuleForCalendar>;

  @Prop({default: () => ''})
  title: string;

  @Prop()
  start!: Dayjs;

  @Prop({default: () => 7})
  numDays: number;

  today: Dayjs = this.dayjs();

  scheduleDropdown: Array<any> = [];

  showModuleCompletedForm: boolean = false;
  selectedModule: Module | null = null;
  selectedInitialDate: string | null = null;

  selectModuleAndShowForm(module: ModuleForCalendar, date: Dayjs): void {
    this.selectedModule = {
      ...module,
      category: undefined,
      tools: undefined,
      instructions: undefined,
      duration: undefined,
      adjustments: undefined,
    };

    this.selectedInitialDate = date.format('YYYY-MM-DD');
    this.showModuleCompletedForm = true;
  }

  onSubmitModuleCompleted() {
    this.showModuleCompletedForm = false;
    this.$emit('reload');
  }

  onCancelModuleCompleted() {
    this.showModuleCompletedForm = false;
  }

  // TODO don't call this too often, use a variable (in the HTML template?) instead
  getModule(moduleUid: string): ModuleForCalendar {
    if (!this.modulesMap) {
      return {
        uid: undefined,
        name: undefined,
        intensity: undefined,
      };
    }
    return this.modulesMap.get(moduleUid);
  }

  get dates() {
    const dates = [];
    for (let i = 0; i < this.numDays; i++) {
      dates.push(this.start.add(i, 'day'));
    }
    return dates;
  }

  get columnWidthString() {
    return 'width: calc(100% / 8);';

    // let width = this.$vuetify.breakpoint.width;
    // let test = document ? document.getElementById("calendarTemplateContainer") : '';
    // if (document) {
    //   const ele = document.getElementById("calendarTemplateContainer");
    //   if (ele) {
    //     width = ele.getBoundingClientRect().width;
    //   }
    // }
    //
    // if (width === 0) {
    //   if (this.$refs.calendarTemplateContainer) {
    //     console.debug('width', this.$refs.calendarTemplateContainer.getBoundingClientRect());
    //     width = this.$refs.calendarTemplateContainer.getBoundingClientRect().width;
    //   }
    // }
    // if (width === 0) {
    //   width = (this.$vuetify.breakpoint.width - 320);
    // }
    //
    // width = Math.ceil(width / (this.numDays + 1));
    //
    // if (width < 120) {
    //   width = 120;
    // }
    // console.debug('setting width', width);
    // return 'width: ' + width + 'px !important; min-width: ' + width + 'px !important; max-width: ' + width + 'px !important;'
  }
}
