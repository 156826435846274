






























































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Category, User} from "@/types/domain";
import {mixins} from "vue-class-component";
import ModuleHeader from "@/components/ModuleHeader.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {CancelTokenSource} from "axios";
import {AxiosMixin} from "@/mixins/axios";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import CategoryPanels from "@/components/coach/CategoryPanels.vue";
import {ModuleSelections} from "@/types/app";

@Component({
  components: {CategoryPanels, StandardFormButtons, CustomSkeletonLoader, ErrorOverlay, StandardFormToolbar, ModuleHeader},
})
export default class ChooseExistingCategoriesAndModulesForm extends mixins(AxiosMixin) {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: ''})
  title!: string;

  @Prop({default: () => null})
  moduleUid!: string | null;

  @Prop({default: () => null})
  category!: Category | null;

  @Prop({default: () => null})
  user!: User | null;

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  availableCategories: Array<Category> = [];

  showDialog: boolean = false;
  valid: boolean = false;

  selections: ModuleSelections = {
    categoryUids: [],
    moduleUids: [],
  };

  cancelTokenSource: CancelTokenSource | undefined;

  @Watch('showSkeletonLoader')
  onShowSkeletonLoaderChanged() {
    if (!this.showSkeletonLoader) {
      this.resetForm();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadAvailableCategories();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  loadAvailableCategories() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http.get(`/api/teams/${this.$store.getters.teamUid}/available-categories`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.availableCategories = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  save() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    const teamUid = this.$route.params.teamUid;
    this.$http.post(`/api/teams/${teamUid}/select-categories-and-modules`, this.selections, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  get selectedTeamName() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.name : '';
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }
}
