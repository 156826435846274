var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-toolbar',{staticClass:"admin-table__toolbar__border",attrs:{"dense":"","flat":""}},[_c('v-checkbox',{staticClass:"mt-5 mr-2",attrs:{"color":"dark-grey","indeterminate":_vm.numSelected > 0 && _vm.numSelected < _vm.pendingEvents.length},on:{"change":function($event){return _vm.toggleEvents($event)}},model:{value:(_vm.isEventCheckboxChecked),callback:function ($$v) {_vm.isEventCheckboxChecked=$$v},expression:"isEventCheckboxChecked"}}),(_vm.numSelected > 0)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.$emit('approve', _vm.pendingEvents.filter(function (event) { return event.isSelected; }))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("check_circle_outline")])],1)]}}],null,false,3039105014)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.approve')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.$emit('delete', _vm.pendingEvents.filter(function (event) { return event.isSelected; }))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,1656813133)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('reload')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.refresh')))])])],1),_c('v-card-title',{staticClass:"py-2 admin-table__card__smaller admin-table__toolbar__border"},[_vm._v(_vm._s(_vm.$t('txt.trainingEvents')))]),_c('v-card-text',{staticClass:"px-0"},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.pendingEvents),function(event){return _c('tr',{key:event.uid},[_c('td',[_c('v-checkbox',{staticStyle:{"margin":"-10px 0","padding-top":"21px"},attrs:{"color":"dark-grey"},model:{value:(event.isSelected),callback:function ($$v) {_vm.$set(event, "isSelected", $$v)},expression:"event.isSelected"}})],1),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_vm._v(_vm._s(_vm.formatTrainingDayAndTime(event.dateTimeOfTraining)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('txt.trainingDay')))])])],1),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.formatUsers(event.players)))])]),_c('td',[_vm._v(_vm._s(event.module.name))]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('strong',_vm._g(_vm._b({},'strong',attrs,false),on),[_vm._v(_vm._s(_vm.formatTime(event.createdAt)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('txt.recordTime')))])])],1)])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }