





















































































import Component, {mixins} from 'vue-class-component';
import {Team, User} from "@/types/domain";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import {Date} from "@/mixins/date";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import {Prop} from "vue-property-decorator";

@Component({
  components: {ErrorOverlay, ConfirmationDialog}
})
export default class TeamMembershipCard extends mixins(Date) {
  @Prop({default: null})
  user!: User;

  panel: Array<number> | null | undefined = null;

  isRemovingFromTeam: boolean = false;
  wasRemovingFromTeamSuccessful: boolean = false;
  showRemoveErrorMessage: boolean = false;

  async confirmToRemoveFromTeam(team: Team, user: User) {
    const confirm: any = this.$refs.confirm;
    if (
        // TODO translate
        await confirm.open(
            "Wirklich aus Team entfernen?",
            `Möchtest du ${user.name} wirklich aus dem Team '${team.name}' entfernen? -
            Die Änderung wird sofort wirksam.`
        )
    ) {
      this.removeFromTeam(team.uid);
    }
  }

  removeFromTeam(teamUid: string) {
    this.isRemovingFromTeam = true;
    this.wasRemovingFromTeamSuccessful = false;

    const userUid = this.$route.params.userUid;
    this.$http.post(`/api/users/${userUid}/remove-from-team/${teamUid}`).then(response => {
      try {
        this.wasRemovingFromTeamSuccessful = true;
        this.user.teams = response.data.teams;
        this.user.pastTeams = response.data.pastTeams;
      } catch (e) {
      }
    }).catch(() => {
      this.showRemoveErrorMessage = true;
    }).finally(() => {
      this.isRemovingFromTeam = false;
    });
  }
};
