
















import {Component} from 'vue-property-decorator';
import Vue from 'vue';

const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea'],
];

@Component({
  components: {},
})
export default class Reporting extends Vue {
  width: number = 2;
  radius: number = 10;
  padding: number = 8;
  lineCap: string = 'round';
  gradient: Array<string> = gradients[5];
  value: Array<number> = [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0];
  gradientDirection: string = 'top';
  fill: boolean = false;
  type: string = 'trend';
  autoLineWidth: boolean = false;
}
