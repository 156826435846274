import axios from 'axios';

// const https = require('https');

process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;

export default axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    // httpsAgent: new https.Agent({
    //     rejectUnauthorized: false,
    // }),
    withCredentials: true,
    headers: {
        // 'Accept-Language': 'de-DE,de;q=0.9',
        // 'X-XSRF-TOKEN': CSRF_TOKEN,
        // 'Access-Control-Allow-Origin': process.env.VUE_APP_API_URL,
    },
    transformResponse: [function (data, headers) {
        const contentType = headers['content-type'];
        if (contentType && (contentType.startsWith('application/json') || contentType.startsWith('text/json'))) {
            return JSON.parse(data);
        }
        return data;
    }],
});
