



















































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Team, User} from "@/types/domain";
import {mixins} from "vue-class-component";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";

@Component({
  components: {StandardFormToolbar, StandardFormButtons, ErrorOverlay, CustomSkeletonLoader},
})
export default class TeamForm extends mixins(AxiosMixin) {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: null})
  teamUid!: string | null;

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';
  showDialog: boolean = false;
  valid: boolean = false;
  select: string | null = null;

  team: Team;

  cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();

    this.team = this.getInitialTeam();
  }

  getInitialTeam(): Team {
    return {uid: '', name: '', categories: [], players: [], archivedPlayers: [], isArchived: false, managers: []};
  }

  @Watch('showSkeletonLoader')
  onShowSkeletonLoaderChanged() {
    if (!this.showSkeletonLoader) {
      this.resetForm();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadTeam();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  loadTeam() {
    if (!this.isEditForm) {
      this.team = this.getInitialTeam();
      this.isLoading = false;
      this.wasLoadingSuccessful = true;
      return;
    }

    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;

    this.$http.get(`/api/teams/${this.teamUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.team = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  save() {
    if (this.isEditForm) {
      this.update();
    } else {
      this.createNew();
    }
  }

  createNew() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.post("/api/teams", {
      name: this.team.name,
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.$emit('submit', response.data);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  update() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.patch(`/api/teams/${this.teamUid}`, {
      name: this.team.name,
      isArchived: this.team.isArchived,
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.$emit('submit');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  deleteTeam() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isDeleting = true;
    this.$http.delete(`/api/teams/${this.teamUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isDeleting = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get isEditForm() {
    return this.teamUid && this.teamUid !== '';
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }
}
