



















































import {mixins} from 'vue-class-component';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {Position, User} from "@/types/domain";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";

@Component({
  components: {ErrorOverlay, CustomSkeletonLoader, StandardErrorCard}
})
export default class PositionsCard extends mixins(Date) {
  // @Prop({default: () => []})
  // value!: Array<Position>;
  @Prop({default: null})
  user!: User;

  availablePositions: Array<Position> = [];
  selectedPositions: Array<Position> = [];

  panel: Array<number> | null | undefined = null;

  breakpoint: number = 1080;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  showErrorMessage: boolean = false;

  mounted() {
    this.loadAvailablePositions();
    this.updateSelectedPositions();
  }

  @Watch('user')
  onUserChanged() {
    this.updateSelectedPositions();
  }

  updateSelectedPositions() {
    this.selectedPositions = !this.user.positions ? [] : this.user.positions;
  }

  loadAvailablePositions() {
    this.isLoading = true;
    this.$http.get(`/api/positions`).then(response => {
      // const ps : Array<Position> = [];
      // response.data.forEach((position: Position) => {
      //   ps.push({
      //     token: position.token,
      //     name: position.name,
      //     order: position.order,
      //     players: [],
      //   })
      // });
      // this.availablePositions = ps;
      this.availablePositions = response.data;
    }).catch(() => {
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  saveState(position: Position) {
    this.isSaving = true;

    let endpoint;
    if (this.isChecked(position)) {
      endpoint = `remove-positions`;
    } else {
      endpoint = `add-positions`;
    }

    this.$http.post(`/api/users/${this.user.uid}/${endpoint}`, {
      tokens: [position.token]
    }).then(response => {
      this.user.positions = response.data;
    }).catch(() => {
      this.showErrorMessage = true;
    }).finally(() => {
      this.updateSelectedPositions();
      this.isSaving = false;
    });
  }

  isChecked(position: Position): boolean {
    if (!this.user.positions) {
      return false;
    }
    return this.user.positions.find(p => {
      return position.token === p.token;
    }) !== undefined;
  }

  get positionTokens() {
    if (!this.user || !this.user.positions) {
      return [];
    }
    return this.user.positions.map(position => position.token);
  }

  get classname() {
    return this.$vuetify.breakpoint.width >= this.breakpoint ? 'full' : 'half';
  }
};
