var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4"},[_c('v-toolbar',{staticClass:"admin-table__toolbar__border",attrs:{"dense":"","flat":""}},[_c('v-checkbox',{staticClass:"mt-5 mr-2",attrs:{"disabled":_vm.hasNoTeams,"color":"dark-grey","indeterminate":_vm.numSelected > 0 && _vm.numSelected < _vm.pendingUsers.length},on:{"change":function($event){return _vm.togglePendingUsers($event)}},model:{value:(_vm.isUserCheckboxChecked),callback:function ($$v) {_vm.isUserCheckboxChecked=$$v},expression:"isUserCheckboxChecked"}}),(_vm.numSelected > 0)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.$emit('approve', _vm.pendingUsers.filter(function (user) { return user.isSelected; }))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("check_circle_outline")])],1)]}}],null,false,2819349227)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.approve')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.$emit('delete', _vm.pendingUsers.filter(function (user) { return user.isSelected; }))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,false,1599958736)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('reload')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.refresh')))])]),(_vm.hasNoTeams && _vm.$vuetify.breakpoint.width > 400)?_c('v-spacer'):_vm._e(),(_vm.hasNoTeams && _vm.$vuetify.breakpoint.width > 400)?_c('router-link',{attrs:{"to":{name: 'manage-teams'}}},[(_vm.hasNoTeams)?_c('span',{staticStyle:{"color":"red","font-size":"0.9em"}},[_c('v-icon',[_vm._v("error_outline")]),_vm._v(" "+_vm._s(_vm.$t('txt.createTeamFirst'))+" ")],1):_vm._e()]):_vm._e()],1),_c('v-card-title',{staticClass:"py-2 admin-table__card__smaller admin-table__toolbar__border"},[_vm._v(_vm._s(_vm.$t('txt.enrollments')))]),_c('v-card-text',{staticClass:"px-0"},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.pendingUsers),function(user){return _c('tr',{key:user.uid},[_c('td',{staticClass:"pr-0"},[_c('v-checkbox',{staticStyle:{"margin":"-10px 0","padding-top":"21px"},attrs:{"disabled":_vm.hasNoTeams || !user.uid,"color":"dark-grey"},model:{value:(user.isSelected),callback:function ($$v) {_vm.$set(user, "isSelected", $$v)},expression:"user.isSelected"}})],1),_c('td',[_c('strong',[_vm._v(_vm._s(user.name))]),_vm._v(" <"+_vm._s(user.mail)+">")]),_c('td',{staticStyle:{"min-width":"150px","max-width":"170px"}},[_c('v-select',{staticClass:"mt-3 pa-0",attrs:{"items":_vm.teams,"item-text":"name","item-value":"uid","no-data-text":_vm.$t('txt.createTeamFirst'),"return-object":"","required":""},model:{value:(user.selectedTeam),callback:function ($$v) {_vm.$set(user, "selectedTeam", $$v)},expression:"user.selectedTeam"}})],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon--selection",class:user.isPlayer ? 'icon--active' : 'icon--inactive',attrs:{"color":user.isPlayer ? '#000000' : '#bab9b9'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIsPlayer(user)}}},'v-icon',attrs,false),on),[_vm._v("sports_handball ")])]}}],null,true)},[(user.isPlayer)?_c('span',[_vm._v(_vm._s(_vm.$t('txt.markAsPlayer.selected')))]):_c('span',[_vm._v(_vm._s(_vm.$t('txt.markAsPlayer.unselected')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1 icon--selection",class:user.isCoach ? 'icon--active' : 'icon--inactive',attrs:{"color":user.isCoach ? '#000000' : '#bab9b9'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleIsCoach(user)}}},'v-icon',attrs,false),on),[_vm._v("sports ")])]}}],null,true)},[(user.isCoach)?_c('span',[_vm._v(_vm._s(_vm.$t('txt.markAsCoach.selected')))]):_c('span',[_vm._v(_vm._s(_vm.$t('txt.markAsCoach.unselected')))])])],1),(_vm.$vuetify.breakpoint.width > 600)?_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.hasNoTeams,"icon":""},on:{"click":function($event){return _vm.$emit('approve', [user])}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("check_circle_outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.approve')))])])],1):_vm._e(),(_vm.$vuetify.breakpoint.width > 600)?_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.formatTime(user.createdAt)))])]):_vm._e()])}),0)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }