import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {Ripple} from 'vuetify/lib/directives';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify, {
    directives: {
        Ripple
    },
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#002066',
                // secondary: '',
                // accent: ''
            },
        },
    },
});
