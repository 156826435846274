




import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import uuid from 'uuid-random';

import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip
} from 'chart.js';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Filler,
    Legend,
    Title,
    Tooltip
);

@Component({
  components: {},
})
export default class ChartJsWrapper extends mixins() {
  @Prop({default: () => null})
  config!: any;

  canvasId: any;

  chart: any = null;

  created() {
    this.canvasId = uuid();
  }

  @Watch('config')
  onDataChanged() {
    this.createChart();
  }

  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    const canvas: any = document.getElementById(this.canvasId);
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        this.chart = new Chart(ctx, this.config);
      }
    }
  }
}
