import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class NumberMixin extends Vue {
    // // NaN is the only value that is not equal to itself
    // isNaN(maybeNaN: number) {
    //     return maybeNaN != maybeNaN;
    // }

    formatMinutes(value: number, pluralDays: string = 'Tage') {
        if (value === null || value === undefined) {
            return null;
        }

        if (value > 60 * 48) {
            return this.formatNumber(value / 60 / 24, 1) + ' ' + pluralDays;
        }
        if (value > 90) {
            return this.formatNumber(value / 60, 1) + ' h';
        }
        return this.formatNumber(value, 0) + ' min';
    }

    formatNumber(value: number, numberOfFractionDigits: number = 2) {
        if (value === null || value === undefined) {
            return null;
        }
        return value.toLocaleString('de-DE', {
            minimumFractionDigits: numberOfFractionDigits,
            maximumFractionDigits: numberOfFractionDigits,
        });
    }

    toInteger(value: string, defaultValue: number) {
        try {
            const number = parseInt(value);
            if (isNaN(number)) {
                return 0;
            }
            return number;
        } catch (e) {
            return defaultValue;
        }
    }
}
