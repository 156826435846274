































































































import Component, {mixins} from 'vue-class-component';
import {User} from "@/types/domain";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {Date} from "@/mixins/date";
import dayjs from "dayjs";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import {Prop} from "vue-property-decorator";

@Component({
  components: {ErrorOverlay, CustomSkeletonLoader}
})
export default class UserDataCard extends mixins(Date) {
  @Prop({default: null})
  user!: User | null;

  panel: Array<number> | null | undefined = null;

  isFormValid: boolean = true;
  menu: boolean = false;
  showUpdateOkMessage: boolean = false;
  showUpdateErrorMessage: boolean = false;
  isUpdatingUserData: boolean = false;

  updateUserData() {
    if (!this.user) {
      return;
    }

    this.showUpdateOkMessage = false;
    this.showUpdateErrorMessage = false;
    this.isUpdatingUserData = true;
    this.$http.patch(`/api/users/${this.user.uid}`, {
      name: this.user.name,
      mail: this.user.mail,
      dateOfBirth: this.user.dateOfBirth,
    }).then(() => {
      this.showUpdateOkMessage = true;
    }).catch(() => {
      this.showUpdateErrorMessage = true;
    }).finally(() => {
      this.isUpdatingUserData = false;
    });
  }

  get formattedDateOfBirth() {
    return this.user && this.user.dateOfBirth ? this.formatTime(this.user.dateOfBirth) : '';
  }

  get currentDate() {
    const dateOfBirthFromStore = this.$store.getters.lastDateOfBirth;

    if (!dateOfBirthFromStore || dateOfBirthFromStore === '') {
      return dayjs().subtract(14, 'year').format("YYYY-MM-DD");
    }

    return dateOfBirthFromStore;
  }

  get minDate() {
    return dayjs().subtract(20, 'year').format("YYYY-MM-DD");
  }

  get maxDate() {
    return dayjs().subtract(5, 'year').format("YYYY-MM-DD");
  }
};
