

































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import Vue from "vue";

@Component({
  components: {StandardFormButtons, StandardFormToolbar, ErrorOverlay},
})
export default class MetricsForm extends mixins(AxiosMixin) {
  @Prop({default: false})
  show!: boolean;

  bodyHeightInCm: number = 150;
  bodyWeightInKg: number = 40;

  isLoading: boolean = true;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  showDialog: boolean = false;
  valid: boolean = false;
  select: string | null = null;

  cancelTokenSource: CancelTokenSource | undefined;

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();
      if (this.$store.getters.latestMetrics) {
        if (this.$store.getters.latestMetrics.bodyHeightInCm) {
          this.bodyHeightInCm = this.$store.getters.latestMetrics.bodyHeightInCm;
        }
        if (this.$store.getters.latestMetrics.bodyWeightInKg) {
          this.bodyWeightInKg = this.$store.getters.latestMetrics.bodyWeightInKg;
        }
      }

      this.showErrorMessage = false;
      this.errorMessage = '';
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  save() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.post(`/api/metrics`, {
      bodyWeightInKg: this.bodyWeightInKg,
      bodyHeightInCm: this.bodyHeightInCm,
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
      this.$store.dispatch('updateLatestMetrics', {
        dateTime: Vue.prototype.dayjs().format('YYYY-MM-DDTHH:mm:ss'),
        bodyHeightInCm: this.bodyHeightInCm,
        bodyWeightInKg: this.bodyWeightInKg,
      });
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  get isDisabled() {
    return this.isSaving;
  }
}
