
















import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import UsersTable from "@/components/UsersTable.vue";
import {User} from "@/types/domain";
import axios, {CancelTokenSource} from "axios";
import {AxiosMixin} from "@/mixins/axios";

@Component({
  components: {UsersTable}
})
export default class TheAdminTeamsPage extends mixins(Auth, AxiosMixin) {
  users: Array<User> = [];
  deactivatedUsers: Array<User> = [];

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  cancelTokenSource: CancelTokenSource | undefined;

  mounted() {
    this.loadUsers();
    this.loadDeactivatedUsers();
  }

  setInitialPasswords() {
    return new Promise((resolve, reject) => {
      this.$http.post(`/auth/set-initial-passwords`).then(response => {
        resolve(response);
      }).catch((reason) => {
        reject(reason);
      }).finally(() => {
      });
    });
  }

  loadUsers() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;

    this.$http.get(`/api/users`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.users = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  loadDeactivatedUsers() {
    this.$http.get(`/api/users?isDeactivated=true`, {}).then(response => {
      this.deactivatedUsers = response.data;
    }).finally(() => {
    });
  }

  get isAdmin() {
    return this.$store.getters.hasAdminRole;
  }
};
