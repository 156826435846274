


























































































































































import {mixins} from 'vue-class-component';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {Team, User} from "@/types/domain";
import {PendingUser} from "@/types/app";

@Component({
  components: {}
})
export default class EnrollmentsTable extends mixins(Date) {
  @Prop({default: () => []})
  users!: Array<User>;

  teams: Array<Team> = this.$store.getters.managedTeams;
  pendingUsers: Array<PendingUser> = [];

  firstTeam: Team | undefined = undefined;

  mounted() {
    this.updateUsers();

    this.firstTeam = this.teams.length > 0 ? this.teams[0] : undefined;
    if (this.firstTeam) {
      this.pendingUsers.forEach(user => {
        user.selectedTeam = this.firstTeam;
      });
    }
  }

  @Watch('users')
  onUsersChanged() {
    this.updateUsers();
  }

  updateUsers() {
    if (!this.users) {
      return;
    }

    const newUsers: Array<PendingUser> = [];
    this.users.forEach(user => {
      const existingUser: undefined | PendingUser = this.pendingUsers.find(u => u.uid === user.uid);
      newUsers.push({
        uid: user.uid,
        name: user.name,
        mail: user.mail,
        dateOfBirth: user.dateOfBirth,
        positions: user.positions,
        managedTeams: user.managedTeams,
        teams: user.teams,
        roles: user.roles,
        createdAt: user.createdAt,
        isDeactivated: user.isDeactivated,
        selectedTeam: existingUser ? existingUser.selectedTeam : this.firstTeam,
        isSelected: existingUser ? existingUser.isSelected : false,
        isCoach: existingUser ? existingUser.isCoach : false,
        isPlayer: existingUser ? existingUser.isPlayer : true,
        latestMetrics: undefined,
        pastTeams: [],
      });
    });
    this.pendingUsers = newUsers;
  }

  togglePendingUsers(isSelected: boolean) {
    this.pendingUsers.forEach(user => {
      user.isSelected = isSelected;
    });
  }

  toggleIsPlayer(user: PendingUser): void {
    user.isPlayer = !user.isPlayer;
    if (!user.isPlayer) {
      user.isCoach = true;
    }
  }

  toggleIsCoach(user: PendingUser): void {
    user.isCoach = !user.isCoach;
    if (!user.isCoach) {
      user.isPlayer = true;
    }
  }

  set isUserCheckboxChecked(state: boolean) {
  }

  get isUserCheckboxChecked() {
    return this.pendingUsers.length > 0 && this.pendingUsers.every(user => user.isSelected);
  }

  get hasNoTeams() {
    return !this.teams || this.teams.length === 0;
  }

  get numSelected() {
    return this.pendingUsers.filter(user => user.isSelected).length;
  }
};
