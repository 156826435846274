import Vue from 'vue';
import VueRouter from 'vue-router';
import Modules from '@/views/TheModulesPage.vue';
import Landing from '@/views/TheLandingPage.vue';
import Registration from '@/views/noauth/TheRegistrationPage.vue';
import Verification from '@/views/noauth/TheVerificationPage.vue';
import Imprint from '@/views/noauth/TheImprintPage.vue';
import Login from '@/views/noauth/TheLoginPage.vue';
import Account from '@/views/TheAccountPage.vue';
import PasswordReset from '@/views/noauth/ThePasswordResetPage.vue';
import PasswordResetOk from '@/views/noauth/ThePasswordResetOkPage.vue';
import Charts from '@/components/Reporting.vue';
import CoachNotifications from '@/views/coach/TheNotificationsPage.vue';
import CoachTeam from '@/views/coach/TheTeamPage.vue';
import CoachTeamPlayer from '@/views/coach/ThePlayerPage.vue';
import CoachTeamModules from '@/views/coach/TheTeamModulesPage.vue';
import CoachModules from '@/views/coach/TheModulesPage.vue';
import CoachTeamTimelines from '@/views/coach/TheTimelinesPage.vue';
import CoachTeamMetrics from '@/views/coach/TheMetricsPage.vue';
import CoachTrainingSchedules from '@/views/coach/TheTrainingSchedulePage.vue';
import TrainingSchedules from '@/views/TheTrainingSchedulePage.vue';
import CoachArchivedTeams from '@/views/coach/TheArchivedPage.vue';
import CoachClub from '@/views/coach/TheClubPage.vue';
import AdminUsers from '@/views/admin/TheAdminUsersPage.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/imprint',
        name: 'imprint',
        component: Imprint,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: Registration,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/verify',
        name: 'verify',
        component: Verification,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password/:token?',
        name: 'reset-password',
        component: PasswordReset,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password-ok',
        name: 'reset-password-ok',
        component: PasswordResetOk,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/',
        name: 'landing',
        component: Landing,
    },
    {
        path: '/modules',
        name: 'modules',
        component: Modules,
    },
    {
        path: '/training-schedules',
        name: 'training-schedules',
        component: TrainingSchedules,
    },
    {
        path: '/coach/notifications',
        name: 'coach-notifications',
        component: CoachNotifications,
    },
    {
        path: '/coach/teams/:teamUid?/players',
        name: 'coach-team-players',
        component: CoachTeam,
    },
    {
        path: '/coach/teams/:teamUid?/players/:userUid',
        name: 'coach-team-player',
        component: CoachTeamPlayer,
    },
    {
        path: '/coach/players/:userUid',
        name: 'coach-player',
        component: CoachTeamPlayer,
    },
    {
        path: '/coach/teams/:teamUid?/modules',
        name: 'coach-team-modules',
        component: CoachTeamModules,
    },
    {
        path: '/coach/teams/:teamUid?/timelines',
        name: 'coach-team-timelines',
        component: CoachTeamTimelines,
    },
    {
        path: '/coach/teams/:teamUid?/metrics',
        name: 'coach-team-metrics',
        component: CoachTeamMetrics,
    },
    {
        path: '/coach/teams/:teamUid?/training-schedules',
        name: 'coach-team-training-schedules',
        component: CoachTrainingSchedules,
    },
    {
        path: '/coach/archive/teams',
        name: 'coach-archived-teams',
        component: CoachArchivedTeams,
    },
    {
        path: '/coach/club',
        name: 'coach-club',
        component: CoachClub,
    },
    {
        path: '/coach/modules',
        name: 'coach-modules',
        component: CoachModules,
    },
    {
        path: '/admin/charts',
        name: 'admin-charts',
        component: Charts,
    },
    {
        path: '/admin/users',
        name: 'admin-users',
        component: AdminUsers,
    },
    {
        path: '/admin/users/:userUid',
        name: 'admin-user',
        component: CoachTeamPlayer,
    },
    {
        path: '/account',
        name: 'account',
        component: Account,
    },
];

const router = new VueRouter({
    routes,
});

/**
 * By default, require authentication for all routes.
 * Per route, authentication can be disabled using: `meta: { requiresAuth: false }`
 */
router.beforeEach((to, from, next) => {
    if (to.matched.every(record => record.meta.requiresAuth === false)) {
        // authentication is disabled for this route
        next();
    } else {
        // authentication is active
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        // let redirectUri = window.location.origin + window.location.pathname + '#' + to.fullPath;
        next({name: 'login', query: {redirect: to.path}});
    }
});

export default router;
