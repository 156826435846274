


































import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import {Team} from "@/types/domain";
import TeamForm from "@/components/TeamForm.vue";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import {Date} from "@/mixins/date";
import TeamPanels from "@/components/coach/TeamPanels.vue";

@Component({
  components: {TeamPanels, StandardErrorCard, TeamForm}
})
export default class TheArchivedPage extends mixins(Auth, Date) {
  archivedTeams: Array<Team> = [];

  showTeamForm: boolean = false;
  selectedTeamUid: string | null = null;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  isLoadingArchivedTeams: boolean = false;
  wasLoadingArchivedTeamsSuccessful: boolean = false;

  mounted() {
    this.loadArchivedTeams();
  }

  loadArchivedTeams() {
    this.isLoadingArchivedTeams = true;
    this.wasLoadingArchivedTeamsSuccessful = false;

    return new Promise((resolve, reject) => {
      this.$http.get(`/api/teams/archived`).then(response => {
        this.archivedTeams = response.data;
        this.archivedTeams.forEach(team => {
          team.isArchived = true; // TODO let the API do this
        });
        this.wasLoadingArchivedTeamsSuccessful = true;
        resolve(this.archivedTeams);
      }).catch((reason) => {
        reject(reason);
      }).finally(() => {
        this.isLoadingArchivedTeams = false;
      });
    });
  }

  selectTeamAndShowTeamForm(teamUid: string | null) {
    this.selectedTeamUid = teamUid;
    this.showTeamForm = true;
  }

  onTeamsChanged() {
    this.setTeamFormClosed();

    this.$http.get('/api/users/current').then(result => {
      this.$store.dispatch('login', result.data);
    });

    this.loadArchivedTeams();
  }

  setTeamFormClosed() {
    this.showTeamForm = false;
  }
};
