























import {Component, Prop} from 'vue-property-decorator';
import {Image} from "@/types/domain";
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";

@Component({
  components: {},
})
export default class ModuleHeader extends mixins(ImageMixin) {
  @Prop({default: () => ''})
  teamName: string | null;

  @Prop({default: () => null})
  categoryImage: Image | null;

  @Prop({default: () => ''})
  categoryName: string | null;

  @Prop({default: () => ''})
  moduleName: string | null;
}
