import Vue from 'vue';
import Component from 'vue-class-component';

const semverGt = require('semver/functions/gt');

@Component
export class Version extends Vue {
    isNewVersionAvailable(expectedVersion: string, currentVersion: string): boolean {
        if (!expectedVersion || !currentVersion) {
            return false;
        }

        try {
            return semverGt(expectedVersion, currentVersion);
        } catch (e) {
            return false;
        }
    }
}
