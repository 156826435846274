





















































































































































































































































































































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Team} from "@/types/domain";
import TeamForm from "@/components/TeamForm.vue";

@Component({
  components: {TeamForm}
})
export default class AppLayout extends Vue {
  @Prop({default: () => false})
  isConnected!: boolean;

  drawer: any = null;
  showMenu: boolean = false;

  showTeamForm: boolean = false;

  created() {
  }

  @Watch('$store.getters.numNotifications')
  onNumNotificationsChanged(currentNum: number, previousNum: number) {
    if (currentNum > previousNum) {
      const element = document.getElementById("noti");
      if (element && element.parentNode) {
        const clonedElement = element.cloneNode(true);
        if (clonedElement) {
          element.parentNode.replaceChild(clonedElement, element);
        }
      }
    }
  }

  logout() {
    this.$http.post('/auth/logout')
        .then(() => {
          this.$store.dispatch('logout').then(() => {
            this.$router.push({name: 'login'}).catch(() => {
            });
          });
        })
        .catch(() => {
        })
        .finally(() => {
        });
  }

  selectTeam(team: Team): void {
    this.$store.dispatch('selectTeam', team);
  }

  onTeamCreated(team: Team): void {
    this.showTeamForm = false;

    this.selectTeam(team);

    this.$http.get('/api/users/current').then(result => {
      this.$store.dispatch('login', result.data);
    });
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  get hasNotifications() {
    return this.$store.getters.numNotifications > 0;
  }

  get isCoachOrAdmin() {
    return this.$store.getters.hasCoachRole || this.$store.getters.hasAdminRole;
  }

  get userName() {
    return this.$store.getters.user ? this.$store.getters.user.name : '';
  }

  get selectedTeamUid() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.uid : undefined;
  }

  get selectedTeamName() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.name : undefined;
  }

  get managedTeams() {
    return this.$store.getters.user ? this.$store.getters.user.managedTeams : [];
  }

  get accountHeader() {
    return this.$store.getters.user ? this.$store.getters.user.name : this.$t('account.myAccount');
  }

  get accountMail() {
    return this.$store.getters.user ? this.$store.getters.user.mail : null;
  }

  get showDrawer() {
    return this.$vuetify.breakpoint.width >= 700;
  }
}
