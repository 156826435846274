



















































































































































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Date } from "@/mixins/date";
import { Category, Module, ModuleAdjustment } from "@/types/domain";
import TeamTable from "@/components/coach/TeamTable.vue";
import ModuleForm from "@/components/ModuleForm.vue";
import { Markdown } from "@/mixins/markdown";
import { Time } from "@/mixins/time";
import ModuleAdjustmentForm from "@/components/ModuleAdjustmentForm.vue";
import { AxiosMixin } from "@/mixins/axios";
import { CancelTokenSource } from "axios";
import { ModuleSelections } from "@/types/app";
import { Duration } from "@/mixins/duration";
import { ModuleMixin } from "@/mixins/module";
import { Container, Draggable } from "vue-smooth-dnd";

@Component({
  components: {
    ModuleAdjustmentForm,
    ModuleForm,
    TeamTable,
    Container,
    Draggable,
  },
})
export default class ModulePanels extends mixins(
  Date,
  Time,
  Markdown,
  AxiosMixin,
  ModuleMixin,
  Duration
) {
  @Prop({ default: () => [] })
  value: Array<string>;

  @Prop({ default: null })
  category: Category | null;

  @Prop({ default: false })
  enableSelections: boolean;

  @Prop({ default: null })
  teamUid: string | null;

  enableSorting: boolean = false;

  panel: Array<number> = [];

  showModuleForm: boolean = false;
  selectedModuleUid: string | null = null;
  selectedCategory: Category | null = null;

  selectedModuleUids: Array<string> = [];

  showAdjustmentForm: boolean = false;
  selectedModule: Module | null = null;
  selectedAdjustmentUid: string | null = null;

  cancelTokenSource: CancelTokenSource | undefined;

  mounted() {
    this.selectedModuleUids = this.value;
  }

  @Watch("value")
  onValueChanged(): void {
    this.selectedModuleUids = this.value;
  }

  @Watch("selectedModuleUids")
  onSelectedModuleUidsChanged() {
    this.$emit("input", this.selectedModuleUids);
    this.$emit("change", this.category);
  }

  selectModuleAndShowForm(moduleUid: string | null, category: Category) {
    this.selectedModuleUid = moduleUid;
    this.selectedCategory = category;
    this.showModuleForm = true;
  }

  selectAdjustmentAndShowForm(
    adjustmentUid: string | null,
    module: Module,
    category: Category
  ) {
    this.selectedAdjustmentUid = adjustmentUid;
    this.selectedModule = module;
    this.selectedCategory = category;
    this.showAdjustmentForm = true;
  }

  selectAdjustment(adjustmentUid: string | null) {
    this.selectedAdjustmentUid = adjustmentUid;
  }

  getDuration(module: Module): string | null {
    if (this.selectedAdjustmentUid) {
      const adjustment: ModuleAdjustment | undefined =
        this.getModuleAdjustment(module);
      if (adjustment) {
        return adjustment.duration;
      }
      return null;
    } else {
      return module.duration;
    }
  }

  getInstructions(module: Module): string {
    if (this.selectedAdjustmentUid) {
      const adjustment: ModuleAdjustment | undefined =
        this.getModuleAdjustment(module);
      if (adjustment) {
        return adjustment.instructions;
      }
      return "";
    } else {
      return module.instructions;
    }
  }

  getModuleAdjustment(module: Module): ModuleAdjustment | undefined {
    return module.adjustments.find(
      (adjustment) => adjustment.uid === this.selectedAdjustmentUid
    );
  }

  onModuleChanged(updatedModule: Module) {
    this.selectedAdjustmentUid = null;

    if (this.category) {
      let wasUpdated = false;

      this.category.modules.forEach((module) => {
        if (module.uid === updatedModule.uid) {
          module.name = updatedModule.name;
          module.duration = updatedModule.duration;
          module.intensity = updatedModule.intensity;
          module.tools = updatedModule.tools;
          module.instructions = updatedModule.instructions;

          wasUpdated = true;
        }
      });

      if (!wasUpdated) {
        this.category.modules.unshift(updatedModule);
      }
    }

    this.setModuleFormClosed();
  }

  onAdjustmentChanged(adjustment: ModuleAdjustment | null) {
    this.selectedAdjustmentUid = adjustment ? adjustment.uid : null;
    // this.loadCategories(); TODO update current view with new data from 'adjustment'
    this.setAdjustmentFormClosed();
  }

  onDrop(dropResult) {
    const { removedIndex, addedIndex } = dropResult;
    const uids = this.category.modules.map((module) => module.uid);

    const uidToMove = uids.splice(removedIndex, 1)[0];
    uids.splice(addedIndex, 0, uidToMove);

    this.$http
      .post(
        `/api/teams/${this.teamUid}/categories/${this.category.uid}/order-modules`,
        { uids },
        {
          cancelToken: this.getToken(this.cancelTokenSource),
        }
      )
      .then((response) => {
        const moduleToMove = this.category.modules.splice(
          removedIndex,
          1
        )[0];
        this.category.modules.splice(addedIndex, 0, moduleToMove);
      })
      .catch(() => {
        // this.setErrorMessage(this.$t('err.save').toString());
      })
      .finally(() => {
        // this.isSaving = false;
      });
  }

  setAdjustmentFormClosed() {
    this.showAdjustmentForm = false;
  }

  setModuleFormClosed() {
    this.showModuleForm = false;
  }

  removeFromTeam(teamUid: string, moduleUid: string, category: Category): void {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    const selection: ModuleSelections = {
      categoryUids: [],
      moduleUids: [moduleUid],
    };
    // this.isSaving = true;
    this.$http
      .post(
        `/api/teams/${this.teamUid}/remove-categories-and-modules`,
        selection,
        {
          cancelToken: this.getToken(this.cancelTokenSource),
        }
      )
      .then((response) => {
        // this.$emit('submit', response.data);
        // TODO
        this.$router.go(0);
      })
      .catch(() => {
        // this.setErrorMessage(this.$t('err.save').toString());
      })
      .finally(() => {
        // this.isSaving = false;
      });
  }
}
