var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.team)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.isCoach && !_vm.team.isArchived)?_c('v-card',{staticClass:"mb-3",attrs:{"flat":""}},[_c('v-btn',{attrs:{"width":"100%"},on:{"click":function($event){return _vm.$emit('show-player-form', _vm.team.uid)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add_circle")]),_vm._v(" "+_vm._s(_vm.$t('btn.addPlayer'))+" ")],1)],1):_vm._e(),(!_vm.team.isArchived || (_vm.team.players && _vm.team.players.length > 0))?_c('v-data-table',{attrs:{"items":_vm.team.players,"headers":_vm.headers,"dense":"","item-key":"name","items-per-page":20,"footer-props":{
          'items-per-page-options': [5, 10, 20, {'value': -1, 'text': _vm.$t('txt.all') }],
          'items-per-page-text': _vm.$t('txt.rowsPerPage'),
        },"hide-default-footer":_vm.team.players.length < 10,"no-data-text":_vm.$t('txt.noPlayersForThatTeam')},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'coach-team-player', params: {teamUid: _vm.$route.params.teamUid, userUid: item.uid}}}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item.mail",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"color":"#585858"}},[_vm._v(_vm._s(item.mail))])]}},{key:"item.positions",fn:function(ref){
        var item = ref.item;
return [(item.positions)?_c('span',[_vm._v(_vm._s(item.positions.map(function (position) { return position.token; }).join(', ')))]):_vm._e()]}},{key:"item.dateOfBirth",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.dateOfBirth))+" ")]}}],null,false,4278222974)}):_vm._e(),(_vm.team.archivedPlayers && _vm.team.archivedPlayers.length > 0)?_c('v-data-table',{attrs:{"items":_vm.team.archivedPlayers,"headers":_vm.headers,"dense":"","item-key":"name","items-per-page":10,"footer-props":{
          'items-per-page-options': [5, 10, 20, {'value': -1, 'text': _vm.$t('txt.all') }],
          'items-per-page-text': _vm.$t('txt.rowsPerPage'),
        },"hide-default-footer":_vm.team.archivedPlayers.length <= 10},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{name:'coach-team-player', params: {teamUid: _vm.$route.params.teamUid, userUid: item.uid}}}},[_c('strong',[_vm._v(_vm._s(item.name))])])]}},{key:"item.mail",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticStyle:{"color":"#585858"}},[_vm._v(_vm._s(item.mail))])]}},{key:"item.positions",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.positions.map(function (position) { return position.token; }))+" ")]}},{key:"item.dateOfBirth",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.dateOfBirth))+" ")]}}],null,false,721785674)}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }