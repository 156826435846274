










































import Vue from "vue";
import {Component, Watch} from 'vue-property-decorator';
import BasicLayout from '@/layouts/BasicLayout.vue';
import AppLayout from '@/layouts/AppLayout.vue';
import {AxiosResponse} from 'axios';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import {mixins} from "vue-class-component";
import {NumberMixin} from "@/mixins/number";
import {Version} from "@/mixins/version";
import {Dayjs} from "dayjs";

@Component({
  components: {BasicLayout, AppLayout},
})
export default class App extends mixins(NumberMixin, Version) {
  socket: any;
  stompClient: any;
  isConnectedToWebSocket: boolean = false;
  enrollmentSubscription: any = null;
  eventSubscription: any = null;
  showNewVersionPopup: boolean = false;
  snoozeNewVersionPopupUntil: Dayjs | null = null;

  created() {
    this.$http.interceptors.request.use(this.onFulfilledRequest, this.onRejectedRequest);
    this.$http.interceptors.response.use(this.onFulfilledResponse, this.onRejectedResponse);

    if (this.$router.currentRoute.meta.requiresAuth === false) {
      this.$http.get('/auth/csrf-token');
    } else {
      this.$http.get('/api/users/current').then(result => {
        this.$store.dispatch('login', result.data);
      }).catch(() => {
        this.$http.get('/auth/csrf-token');
      });
    }
  }

  mounted() {
    if (this.$store.getters.hasAdminRole || this.$store.getters.hasCoachRole) {
      this.connectSocket();
    }
  }

  @Watch('$store.getters.numNotifications')
  onNumNotificationsChanged(currentNum: number, previousNum: number) {
    let numNotifications = '';
    if (currentNum > 0) {
      numNotifications = " (" + currentNum + ")";
    }
    document.title = "Löwentraining" + numNotifications;
  }

  @Watch('$store.getters.isLoggedIn')
  onLoginChanged() {
    this.connectSocketIfAuthorized();
  }

  @Watch('$store.getters.csrfToken')
  onCsrfTokenChanged() {
    this.connectSocketIfAuthorized();
  }

  onFulfilledRequest(config: any) {
    if (['post', 'put', 'patch', 'delete'].indexOf(config.method) !== -1) {
      config.headers['X-CSRF-TOKEN'] = this.$store.getters.csrfToken;
    }
    config.headers['X-FRONTEND-VERSION'] = this.$store.getters.appVersion;
    return config;
  }

  onRejectedRequest(error: any) {
    return Promise.reject(error);
  }

  onFulfilledResponse(response: AxiosResponse) {
    const csrfToken = (response && response.headers && response.headers['x-csrf-token']) || undefined;
    if (csrfToken !== undefined) {
      this.$store.dispatch('updateCsrfToken', csrfToken);
    }

    if (!this.snoozeNewVersionPopupUntil || Vue.prototype.dayjs().isAfter(this.snoozeNewVersionPopupUntil)) {
      const expectedFrontendVersion = (response && response.headers && response.headers['x-expected-frontend-version']) || undefined;
      this.showNewVersionPopup = this.isNewVersionAvailable(expectedFrontendVersion, this.$store.getters.appVersion);
    }

    return response;
  }

  onRejectedResponse(error: any) {
    return new Promise((resolve, reject) => {
      /**
       * Check if one of the requests fails with 401/Unauthorized. If so, automatically re-login the user.
       *
       * We make one important assumption here which is:
       *
       * """
       * Should one of the requests of our app return 401,
       * then the backend auth endpoint will also return 401 (or at least does not authenticate the user).
       * """
       *
       * If the above assumption is not true and the backend auth endpoint still authenticates the user,
       * then we run in an endless loop.
       */
      if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        if (this.$router.currentRoute.meta.requiresAuth === false) {
          this.$http.get('/auth/csrf-token');
        } else {
          this.$store.dispatch('logout').then(() => {
            if (this.$router.currentRoute.name !== 'login') { // should always be true, since the login route should not require auth, but anyway
              this.$router.push({name: 'login', query: {redirect: this.$route.path}}).then(() => {
                this.$http.get('/auth/csrf-token');
              });
            }
          });
        }
      }
      if (error.response && error.response.status === 403 && error.config && !error.config.__isRetryRequest) {
        this.$http.get('/auth/csrf-token');
      }
      reject(error);
    });
  }

  connectSocketIfAuthorized() {
    if (this.$store.getters.isLoggedIn && this.$store.getters.csrfToken && (this.$store.getters.hasAdminRole || this.$store.getters.hasCoachRole)) {
      this.connectSocket();
    } else {
      this.disconnectSocket();
    }
  }

  connectSocket() {
    if (this.isConnectedToWebSocket || !this.$store.getters.csrfToken) {
      return;
    }

    this.socket = new SockJS(`${process.env.VUE_APP_WS_URL}/ws`);
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.debug = (str: any) => {
      // console.debug(str);
    };
    const headers = {
      'login': '',
      'passcode': '',
      'X-CSRF-TOKEN': this.$store.getters.csrfToken,
    };
    const callbackUsers = (tick: any) => this.$store.dispatch('numPendingUsers', this.toInteger(tick.body, 0));
    const callbackEvents = (tick: any) => this.$store.dispatch('numPendingEvents', this.toInteger(tick.body, 0));
    this.stompClient.connect(
        headers,
        (frame: any) => {
          this.isConnectedToWebSocket = true;
          this.enrollmentSubscription = this.stompClient.subscribe(`/topic/enrollments`, callbackUsers);
          this.eventSubscription = this.stompClient.subscribe(`/user/queue/events`, callbackEvents);
          setTimeout(this.stompClient.send(`/app/enrollments`), 1500);
          setTimeout(this.stompClient.send(`/app/events`), 1500);
        },
        () => {
          this.isConnectedToWebSocket = false;
        }
    );
  }

  disconnectSocket() {
    // try {
    //   if (this.eventSubscription) {
    //     this.eventSubscription.unsubscribe();
    //   }
    //   if (this.enrollmentSubscription) {
    //     this.enrollmentSubscription.unsubscribe();
    //   }
    //   if (this.stompClient && this.stompClient.ws && this.stompClient.ws.readyState === WebSocket.OPEN) {
    //     this.stompClient.disconnect();
    //   }
    // } catch (e) {
    //   console.debug(e);
    // }
  }

  snoozeUpdateNotification() {
    this.snoozeNewVersionPopupUntil = Vue.prototype.dayjs().add(8, 'hour');
    this.showNewVersionPopup = false;
  }

  reloadPage() {
    window.location.reload();
  }

  get layout() {
    return (this.$store.getters.isLoggedIn ? 'app' : 'basic') + '-layout';
  }
}
