





















import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {NumberMixin} from "@/mixins/number";

@Component({
  components: {},
})
export default class MetricValue extends mixins(NumberMixin) {
  @Prop({default: ''})
  title!: string;

  @Prop({default: null})
  value!: number | null;

  @Prop({default: 0})
  numberOfFractionDigits!: number;

  @Prop({default: null})
  suffix!: string | null;

  @Prop({default: null})
  minutes!: number | null;
}
