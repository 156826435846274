




























import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {NumberMixin} from "@/mixins/number";

@Component({
  components: {},
})
export default class TimeSinceLastEventComponent extends mixins(NumberMixin) {
  @Prop({default: ''})
  title: string;

  @Prop({default: null})
  regenerationLeftInMinutes: number;

  @Prop({default: null})
  regenerationInHours: number;

  @Prop({default: 100})
  regenerationInPercent: number;
}
