

























import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import {Markdown} from "@/mixins/markdown";
import {Time} from "@/mixins/time";
import {ImageMixin} from "@/mixins/image";
import {User} from "@/types/domain";
import {Watch} from "vue-property-decorator";
import TimelineComponent from "@/components/TimelineComponent.vue";

@Component({
  components: {TimelineComponent}
})
export default class TheTimelinesPage extends mixins(Auth, Markdown, Time, ImageMixin) {
  players: Array<User> = [];
  tab: any = null;

  mounted() {
    this.getPlayers();
  }

  @Watch('tab')
  onTabChanged() {
    if (this.$route.query.userUid !== this.tab) {
      this.$router.push({name: 'coach-team-timelines', params: this.$route.params, query: {userUid: this.tab}});
    }
  }

  @Watch('$store.getters.teamUid')
  onTeamSelectionChanged() {
    this.getPlayers();
  }

  getPlayers() {
    if (this.$store.getters.selectedTeam) {
      this.$http.get(`/api/teams/${this.$store.getters.selectedTeam.uid}`).then(response => {
        this.players = response.data.players;
        // this.wasLoadingSuccessful = true;
        if (this.$route.query.userUid) {
          this.tab = this.$route.query.userUid;
        }
      }).finally(() => {
        // this.isLoading = false;
      });
    }
  }
};
