





























































































import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Date } from "@/mixins/date";
import { Category } from "@/types/domain";
import TeamTable from "@/components/coach/TeamTable.vue";
import ModulePanels from "@/components/ModulePanels.vue";
import CategoryForm from "@/components/CategoryForm.vue";
import { ImageMixin } from "@/mixins/image";
import { ModuleSelections, SelectableCategory } from "@/types/app";
import { CancelTokenSource } from "axios";
import { AxiosMixin } from "@/mixins/axios";
import { Container, Draggable } from "vue-smooth-dnd";

@Component({
  components: { CategoryForm, ModulePanels, TeamTable, Container, Draggable },
})
export default class OrderableCategoryPanels extends mixins(
  Date,
  ImageMixin,
  AxiosMixin
) {
  @Prop({ default: () => [] })
  categories: Array<Category>;

  @Prop({ default: false })
  enableSelections: boolean;

  @Prop({ default: false })
  enableSorting: boolean;

  @Prop({ default: null })
  teamUid: string | null;

  panel: Array<number> = [];

  showCategoryForm: boolean = false;
  selectedCategoryUid: string | null = null;

  selectableCategories: Array<SelectableCategory> = [];

  cancelTokenSource: CancelTokenSource | undefined;

  onDrop(dropResult) {
    const { removedIndex, addedIndex } = dropResult;
    const uids = this.selectableCategories.map((category) => category.uid);

    const uidToMove = uids.splice(removedIndex, 1)[0];
    uids.splice(addedIndex, 0, uidToMove);

    this.$http
      .post(
        `/api/teams/${this.teamUid}/order-categories`,
        { uids },
        {
          cancelToken: this.getToken(this.cancelTokenSource),
        }
      )
      .then((response) => {
        // this.$emit('submit', response.data);
        // // TODO
        // this.$router.go(0);

        const categoryToMove = this.selectableCategories.splice(
          removedIndex,
          1
        )[0];
        this.selectableCategories.splice(addedIndex, 0, categoryToMove);
      })
      .catch(() => {
        // this.setErrorMessage(this.$t('err.save').toString());
      })
      .finally(() => {
        // this.isSaving = false;
      });
  }

  mounted() {
    this.selectableCategories = this.getCopy(this.categories);
  }

  @Watch("categories")
  onCategoriesChanged() {
    this.selectableCategories = this.getCopy(this.categories);
  }

  onChanged(category: SelectableCategory): void {
    if (!category) {
      return;
    }

    if (
      !category.selectedModuleUids ||
      category.selectedModuleUids.length === 0
    ) {
      category.selectionValue = null;
    } else {
      category.selectionValue = category.uid;
    }

    this.$emit("input", this.value());
  }

  value(): ModuleSelections {
    const selectedModuleUids: Array<string> = [];
    this.selectableCategories
      .filter((category) => category.selectionValue != null)
      .map((category) => category.selectedModuleUids)
      .forEach((arr) => {
        selectedModuleUids.push(...arr);
      });

    return {
      categoryUids: this.selectableCategories
        .filter((category) => category.selectionValue !== null)
        .map((category) => category.uid),
      moduleUids: selectedModuleUids,
    };
  }

  updateSelection(category: SelectableCategory, isSelected: boolean): void {
    category.selectedModuleUids = isSelected
      ? category.modules.map((module) => module.uid)
      : [];
    category.selectionValue = isSelected ? category.uid : null;

    this.$emit("input", this.value());
  }

  selectCategoryAndShowForm(categoryUid: string | null) {
    this.selectedCategoryUid = categoryUid;
    this.showCategoryForm = true;
  }

  getCopy(categories: Array<Category>): Array<SelectableCategory> {
    const selectableCategories: Array<SelectableCategory> = [];
    if (categories) {
      categories.forEach((category) => {
        selectableCategories.push({
          uid: category.uid,
          name: category.name,
          image: category.image,
          modules: category.modules,
          selectionValue: null,
          selectedModuleUids: [],
          regenerationInHours: category.regenerationInHours, // TODO remove
          regeneration: category.regeneration,
        });
      });
    }
    return selectableCategories;
  }

  removeFromTeam(teamUid: string, category: Category): void {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    const selection: ModuleSelections = {
      categoryUids: [category.uid],
      moduleUids: [],
    };
    // this.isSaving = true;
    this.$http
      .post(`/api/teams/${teamUid}/remove-categories-and-modules`, selection, {
        cancelToken: this.getToken(this.cancelTokenSource),
      })
      .then((response) => {
        // this.$emit('submit', response.data);
        // TODO
        this.$router.go(0);
      })
      .catch(() => {
        // this.setErrorMessage(this.$t('err.save').toString());
      })
      .finally(() => {
        // this.isSaving = false;
      });
  }

  // get selectableCategories(): Array<SelectableCategory> {
  //   console.debug('CategoryPanels::computed selectableCategories');
  //   const selectableCategories: Array<SelectableCategory> = [];
  //   if (this.categories) {
  //     this.categories.forEach(category => {
  //       selectableCategories.push({
  //         uid: category.uid,
  //         name: category.name,
  //         image: category.image,
  //         modules: category.modules,
  //         selectionValue: null,
  //         selectedModuleUids: [],
  //       });
  //     });
  //   }
  //   return selectableCategories;
  // }
}
