

































import {Component, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class ErrorOverlay extends Vue {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: ''})
  errorMessage!: string;

  @Watch('show')
  onShowChanged() {
    this.showOverlay = this.show;
  }

  showOverlay: boolean = false;

  close() {
    this.$emit('update:show', false);
  }
}
