


















































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {User} from "@/types/domain";
import {mixins} from "vue-class-component";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import {Date} from "@/mixins/date";

@Component({
  components: {StandardFormToolbar, StandardFormButtons, ErrorOverlay, CustomSkeletonLoader},
})
export default class PlayerForm extends mixins(AxiosMixin, Date) {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: null})
  teamUid!: string | null;

  @Prop({default: false})
  addAsCoach!: boolean;

  availableUsers: Array<User> = [];

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';
  showDialog: boolean = false;
  valid: boolean = false;
  select: string | null = null;

  usersSearchInput: string = '';

  selectedPlayers: Array<User> = [];

  cancelTokenSource: CancelTokenSource | undefined;

  @Watch('showSkeletonLoader')
  onShowSkeletonLoaderChanged() {
    if (!this.showSkeletonLoader) {
      this.resetForm();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadAvailableUsers();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  closeUsers() {
    this.$nextTick(() => {
      const selectElement: any = this.$refs.users;
      if (selectElement) {
        selectElement.isMenuActive = false;
      }
    });
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  loadAvailableUsers() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;

    const endpoint = this.addAsCoach ? '/available-coaches' : '/available-players';
    this.$http.get(`/api/teams/${this.teamUid}${endpoint}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.availableUsers = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  save() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;

    const endpoint = this.addAsCoach ? '/add-coaches' : '/add-players';
    this.$http.post(`/api/teams/${this.teamUid}${endpoint}`, {
      userUids: this.selectedPlayers.map(player => player.uid),
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  filterItems(item: User, queryText: string, itemText: string): boolean {
    if (itemText.toLowerCase().includes(queryText.toLowerCase())) {
      return true;
    }

    return !!(item.dateOfBirth && item.dateOfBirth.includes(queryText));
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }
}
