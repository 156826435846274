







































































import {Component, Watch} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class TheLoginPage extends Vue {
  mail: any = '';
  password: any = '';

  isFormValid: boolean = false;
  isLoggingIn: boolean = false;
  showError: boolean = false;

  intervalHandler: any = null;
  checkCounter: number = 0;

  @Watch('$route.query.mail')
  onMailChanged() {
    this.mail = this.$route.query.mail;
  }

  mounted() {
    this.mail = this.$store.getters.mail;
    if (this.$route.query.mail) {
      this.mail = this.$route.query.mail;
    }
    setTimeout(this.redirectIfLoggedIn, 800);
    // this.intervalHandler = setInterval(this.executeLoginCheck, 2000);
  }

  executeLoginCheck() {
    this.checkCounter++;

    if (this.checkCounter > 200) {
      this.clearInterval();
    }

    this.redirectIfLoggedIn();
  }

  clearInterval() {
    if (this.intervalHandler) {
      clearInterval(this.intervalHandler);
    }
  }

  redirectIfLoggedIn() {
    if (this.isLoggedIn) {
      this.redirect();
    }
  }

  redirect() {
    this.clearInterval();
    if (this.$route.query.redirect) {
      this.$router.push({path: this.$route.query.redirect.toString()}).catch(() => {
        // console.debug('error on router.push after login', error);
        // this.$router.push({name: 'landing'});
      });
    } else {
      this.$router.push({name: 'landing'});
    }
  }

  login() {
    this.isLoggingIn = true;
    this.showError = false;
    const params = new URLSearchParams();
    params.append('mail', this.mail);
    params.append('password', this.password);

    this.$http.post('/auth/login', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }).then(response => {
      this.$store.dispatch('login', response.data);
      this.redirect();
    }).catch(() => {
      this.showError = true;
    }).finally(() => {
      this.isLoggingIn = false;
    });
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }
};
