





































































import Component, { mixins } from "vue-class-component";
import { Auth } from "@/mixins/auth";
import { Category, Module, User } from "@/types/domain";
import { Markdown } from "@/mixins/markdown";
import CategoryForm from "@/components/CategoryForm.vue";
import { Time } from "@/mixins/time";
import { ImageMixin } from "@/mixins/image";
import { Watch } from "vue-property-decorator";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import ChooseExistingCategoriesAndModulesForm from "@/components/coach/ChooseExistingCategoriesAndModulesForm.vue";
import CategoryPanels from "@/components/coach/OrderableCategoryPanels.vue";

@Component({
  components: {
    CategoryPanels,
    ChooseExistingCategoriesAndModulesForm,
    StandardErrorCard,
    CategoryForm,
  },
})
export default class TheTeamModulesPage extends mixins(
  Auth,
  Markdown,
  Time,
  ImageMixin
) {
  categories: Array<Category> = [];
  selectedUser: User | null = null;

  enableSorting: boolean = false;

  showChooseExistingForm: boolean = false;
  showCategoryForm: boolean = false;
  selectedCategoryUid: string | null = null;

  selectedCategory: Category | null = null;

  selectedModuleUid: string | null = null;

  showAdjustmentForm: boolean = false;
  selectedModule: Module | null = null;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  mounted() {
    this.loadCategories();
  }

  @Watch("$store.getters.teamUid")
  onTeamSelectionChanged() {
    this.$router.push({
      name: "coach-team-modules",
      params: { teamUid: this.$store.getters.teamUid },
    });
  }

  @Watch("$route.params.teamUid")
  onTeamUidChanged() {
    this.loadCategories();
  }

  loadCategories() {
    const teamUid = this.getSelectedTeamUid();

    if (!teamUid) {
      return;
    }

    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http
      .get(`/api/teams/${teamUid}/categories`)
      .then((response) => {
        this.categories = response.data;
        this.wasLoadingSuccessful = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onCategoriesChanged() {
    this.loadCategories();
    this.setCategoryFormsClosed();
  }

  selectUser(user: User) {
    this.selectedUser = user;
  }

  selectCategoryAndShowForm(categoryUid: string | null) {
    this.selectedCategoryUid = categoryUid;
    this.showCategoryForm = true;
  }

  setCategoryFormsClosed() {
    this.showCategoryForm = false;
    this.showChooseExistingForm = false;
  }

  setAdjustmentFormClosed() {
    this.showAdjustmentForm = false;
  }

  getSelectedTeamUid() {
    return this.$store.getters.teamUid;
  }

  get isCoach() {
    return this.$store.getters.hasCoachRole;
  }
}
