import { render, staticRenderFns } from "./ChartJsWrapper.vue?vue&type=template&id=ff1adcfa&scoped=true&"
import script from "./ChartJsWrapper.vue?vue&type=script&lang=ts&"
export * from "./ChartJsWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff1adcfa",
  null
  
)

export default component.exports