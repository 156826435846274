import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class Recurrence extends Vue {
    formatWeekdays(weekdays: Array<string>) {
        if (!weekdays) {
            return '';
        }

        return weekdays.map(weekday => {
            const day = weekday.slice(-2);

            if (weekday.length > 2) {
                const numberPart = weekday.substring(0, weekday.length - 2);
                const num = parseInt(numberPart);
                // TODO translate
                return 'am ' + (num < 0 ? this.$t('txt.last') : Math.abs(num) + '.') + ' ' + this.$t('txt.weekday.' + day);
            }

            return this.$t('txt.weekday.' + day);
        }).join(', ');
    }
}
