





















































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Category, Module, ModuleAdjustment, User} from "@/types/domain";
import {mixins} from "vue-class-component";
import {Markdown} from "@/mixins/markdown";
import ModuleHeader from "@/components/ModuleHeader.vue";
import {ImageMixin} from "@/mixins/image";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import {Duration} from "@/mixins/duration";

@Component({
  components: {ErrorOverlay, StandardFormButtons, CustomSkeletonLoader, StandardFormToolbar, ModuleHeader},
})
export default class ModuleAdjustmentForm extends mixins(Markdown, ImageMixin, AxiosMixin, Duration) {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: () => null})
  adjustmentUid!: string | null;

  @Prop({default: () => null})
  category!: Category | null;

  @Prop({default: () => null})
  module!: Module;

  @Prop({default: () => null})
  teamUid!: string | null;

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  availablePlayers: Array<User> = [];

  adjustment: ModuleAdjustment;

  showDialog: boolean = false;
  valid: boolean = false;
  select: string | null = null;

  cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();
    this.adjustment = this.getInitialAdjustment();
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  getInitialAdjustment(): ModuleAdjustment {
    if (this.module) {
      return {uid: '', instructions: this.module.instructions, duration: this.module.duration, players: []};
    }
    return {uid: '', instructions: '', duration: "PT60M", players: []};
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadPlayers();
      this.loadAdjustment();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  loadPlayers() {
    this.$http.get(`/api/teams/${this.teamUid}/players`).then(response => {
      this.availablePlayers = response.data;
    });
  }

  loadAdjustment() {
    if (!this.isEditForm) {
      this.adjustment = this.getInitialAdjustment();
      this.isLoading = false;
      this.wasLoadingSuccessful = true;
      return;
    }

    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http.get(`/api/modules/${this.module.uid}/adjustments/${this.adjustmentUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.adjustment = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  save() {
    this.persist();
  }

  persist() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    const userUids = this.adjustment.players.map(player => player.uid);
    this.$http.patch(`/api/modules/${this.module.uid}`, {
      userUids,
      instructions: this.adjustment.instructions,
      duration: {
        value: this.toSeconds(this.adjustment.duration),
        unitToken: 'seconds',
      }
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      const module: Module = response.data;
      const adjustment: ModuleAdjustment | undefined = module.adjustments.find(adjustment => {
        const playerUids = adjustment.players.map(player => player.uid);
        return userUids.length === playerUids.length && userUids.every((value, index) => value === playerUids[index]);
      });
      this.$emit('submit', adjustment);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  closeDialog() {
    this.$emit('cancel');
  }

  deleteAdjustment() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isDeleting = true;
    this.$http.delete(`/api/modules/adjustments/${this.adjustmentUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isDeleting = false;
    });
  }

  get isEditForm() {
    return this.adjustmentUid && this.adjustmentUid !== '';
  }

  get durationInMinutes() {
    return this.toMinutes(this.adjustment.duration);
  }

  get selectedTeamName() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.name : '';
  }

  set durationInMinutes(minutes) {
    this.adjustment.duration = "PT" + minutes + "M";
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }
}
