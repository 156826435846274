




























































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Category, Module} from "@/types/domain";
import {mixins} from "vue-class-component";
import {Markdown} from "@/mixins/markdown";
import ModuleHeader from "@/components/ModuleHeader.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {CancelTokenSource} from "axios";
import {AxiosMixin} from "@/mixins/axios";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {Duration} from "@/mixins/duration";

@Component({
  components: {StandardFormButtons, CustomSkeletonLoader, ErrorOverlay, StandardFormToolbar, ModuleHeader},
})
export default class ModuleForm extends mixins(Markdown, AxiosMixin, Duration) {
  @Prop({default: false})
  show: boolean;

  @Prop({default: ''})
  title: string;

  @Prop({default: null})
  moduleUid: string | null;

  @Prop({default: null})
  category: Category | null;

  @Prop({default: null})
  teamUid: string | null;

  isLoading: boolean = true;
  wasLoadingSuccessful: boolean = false;

  isSaving: boolean = false;
  isDeleting: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  module: Module;

  showDialog: boolean = false;
  valid: boolean = false;

  cancelTokenSource: CancelTokenSource | undefined;

  constructor() {
    super();
    this.module = this.getInitialModule();
  }

  getInitialModule(): Module {
    return {uid: '', name: '', instructions: '', tools: '', adjustments: [], intensity: 3, duration: "PT60M", category: null};
  }

  @Watch('showSkeletonLoader')
  onShowSkeletonLoaderChanged() {
    if (!this.showSkeletonLoader) {
      this.resetForm();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();

      this.showErrorMessage = false;
      this.errorMessage = '';

      this.loadModule();
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.reset();
    }
  }

  loadModule() {
    if (!this.isEditForm) {
      this.module = this.getInitialModule();
      this.isLoading = false;
      this.wasLoadingSuccessful = true;
      return;
    }

    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http.get(`/api/modules/${this.moduleUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.module = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  save() {
    if (this.isEditForm) {
      this.update();
    } else {
      this.createNew();
    }
  }

  createNew() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.post("/api/modules", {
      teamUid: this.teamUid,
      categoryUid: this.category ? this.category.uid : undefined,
      name: this.module.name,
      tools: this.module.tools,
      instructions: this.module.instructions,
      intensity: this.module.intensity,
      duration: {
        value: this.toMinutes(this.module.duration),
        unitToken: 'minutes',
      },
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.$emit('submit', response.data);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  update() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    this.$http.patch(`/api/modules/${this.moduleUid}`, {
      name: this.module.name,
      tools: this.module.tools,
      instructions: this.module.instructions,
      intensity: this.module.intensity,
      duration: {
        value: this.toMinutes(this.module.duration),
        unitToken: 'minutes',
      }
    }, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(response => {
      this.$emit('submit', response.data);
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  closeDialog() {
    this.$emit('cancel');
  }

  deleteModule() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isDeleting = true;
    this.$http.delete(`/api/modules/${this.moduleUid}`, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('delete');
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isDeleting = false;
    });
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  get isEditForm() {
    return this.moduleUid && this.moduleUid !== '';
    // return Vue.prototype.lodash.isString(this.moduleUid);
  }

  get categoryName() {
    return this.category ? this.category.name : '';
  }

  get durationInMinutes() {
    return this.toMinutes(this.module.duration);
  }

  set durationInMinutes(minutes) {
    this.module.duration = "PT" + minutes + "M";
  }

  get selectedTeamName() {
    return this.$store.getters.selectedTeam ? this.$store.getters.selectedTeam.name : '';
  }

  get isDisabled() {
    return this.isSaving || this.isDeleting;
  }

  get showSkeletonLoader() {
    return this.isLoading || !this.wasLoadingSuccessful;
  }

  get sliderColor() {
    if (this.module.intensity === 5) {
      return '#c10000';
    } else if (this.module.intensity === 4) {
      return '#ff8433';
    } else if (this.module.intensity === 3) {
      return '#f5ca71';
    } else if (this.module.intensity === 2) {
      return '#8bb2fc';
    } else {
      return '#39740a';
    }
  }
}
