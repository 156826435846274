








































































































import {mixins} from 'vue-class-component';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {TrainingEvent, User} from "@/types/domain";
import {PendingEvent} from "@/types/app";

@Component({
  components: {}
})
export default class EventsTable extends mixins(Date) {
  @Prop({default: () => []})
  events!: Array<TrainingEvent>;

  pendingEvents: Array<PendingEvent> = [];

  mounted() {
    this.updateEvents();
  }

  @Watch('events')
  onEventsChanged() {
    this.updateEvents();
  }

  updateEvents() {
    if (!this.events) {
      return;
    }

    const newEvents: Array<PendingEvent> = [];
    this.events.forEach(event => {
      const existingEvent = this.pendingEvents.find(evt => evt.uid === event.uid);
      newEvents.push({
        uid: event.uid,
        dateTimeOfTraining: event.dateTimeOfTraining,
        createdAt: event.createdAt,
        isSelected: existingEvent ? existingEvent.isSelected : false,
        module: event.module,
        players: event.players,
        team: event.team,
      });
    });
    this.pendingEvents = newEvents;
  }

  toggleEvents(isSelected: boolean) {
    this.pendingEvents.forEach(event => {
      event.isSelected = isSelected;
    });
  }

  formatUsers(users: Array<User>) {
    if (!users) {
      return '';
    }
    return users.map(user => user.name).join(" & ");
  }

  set isEventCheckboxChecked(state: boolean) {
  }

  get isEventCheckboxChecked() {
    return this.pendingEvents.length > 0 && this.pendingEvents.every(event => event.isSelected);
  }

  get numSelected() {
    return this.pendingEvents.filter(event => event.isSelected).length;
  }
};
