




import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import {ModuleMixin} from "@/mixins/module";
import {Date} from "@/mixins/date";
import {Metrics} from "@/types/domain";
import ChartJsWrapper from "@/components/ChartJsWrapper.vue";

@Component({
  components: {ChartJsWrapper, ErrorOverlay, CustomSkeletonLoader},
})
export default class SizeAndWeightDiagram extends mixins(Date, ImageMixin, ModuleMixin) {
  @Prop({default: () => null})
  userUid!: null | String;

  chartConfig: any = {};
  chartOptions: any = {};

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  mounted() {
    this.loadMetrics();
  }

  @Watch('userUid')
  onUserUidChanged() {
    this.loadMetrics();
  }

  loadMetrics() {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.wasLoadingSuccessful = false;

      let url = `/api/metrics`;
      if (this.userUid) {
        url = `/api/metrics/${this.userUid}`;
      }

      this.$http.get(url, {}).then(response => {
        const labels: Array<string> = [];
        const heightData: any = [];
        const weightData: any = [];

        if (response.data) {
          for (let i = 0; i < response.data.length; i++) {
            const entry: Metrics = response.data[i];
            if (!entry) {
              continue;
            }
            labels.push(this.formatTime(entry.dateTime));
            heightData.push(entry.bodyHeightInCm);
            weightData.push(entry.bodyWeightInKg);
          }
        }

        const datasets: Array<any> = [];
        datasets.push({
          label: 'Größe [cm]',
          yAxisID: 'left-y-axis',
          data: heightData,
          borderWidth: 1,
          borderColor: 'rgb(25,118,210)',
          backgroundColor: 'rgba(25,118,210,0.33)',
        });
        datasets.push({
          label: 'Gewicht [kg]',
          yAxisID: 'right-y-axis',
          data: weightData,
          borderWidth: 1,
          borderColor: 'rgb(76,175,80)',
          backgroundColor: 'rgba(76,175,80,0.33)',
        });

        this.chartConfig = {
          type: 'line',
          data: {
            labels,
            datasets
          },
          options: this.chartOptions,
        };
      }).then(() => {
        this.wasLoadingSuccessful = true;
        resolve();
      }).catch(e => {
        reject(e);
      }).finally(() => {
        this.isLoading = false;
      });
    });
  }
}
