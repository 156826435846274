






















































































import {Component, Watch} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class ThePasswordResetPage extends Vue {
  mail: any = '';
  isFormValid: boolean = false;
  isResettingPassword: boolean = false;
  isTokenValid: boolean = false;
  password: string = '';
  showError: boolean = false;

  requestHasBeenSent: boolean = false;

  @Watch('$route.params.token')
  onTokenChanged() {
    this.requestHasBeenSent = false;
    this.validateToken();
  }

  created() {
    this.mail = this.$route.query.mail;
    this.validateToken();
  }

  requestPasswordReset() {
    this.showError = false;
    this.isResettingPassword = true;
    this.$http.post('/auth/reset-password', {mail: this.mail}).then(() => {
      this.requestHasBeenSent = true;
    }).catch(() => {
      this.showError = true;
    }).finally(() => {
      this.isResettingPassword = false;
    });
  }

  validateToken() {
    this.showError = false;
    if (this.$route.params.token) {
      this.$http.get('/auth/mail/' + this.$route.params.token).then(response => {
        this.mail = response.data.mail;
        this.isTokenValid = true;
      }).catch(() => {
        this.form.reset();
        this.isTokenValid = false;
      }).finally(() => {
      });
    }
  }

  setNewPassword() {
    if (this.$route.params.token) {
      this.showError = false;
      this.isResettingPassword = true;
      this.$http.post('/auth/password/' + this.$route.params.token, {
        mail: this.mail,
        password: this.password
      }).then(() => {
        this.$router.push({name: 'reset-password-ok', query: {mail: this.mail}});
      }).catch(() => {
        this.showError = true;
      }).finally(() => {
        this.isResettingPassword = false;
      });
    }
  }

  get form(): Vue & { reset: () => boolean } {
    return this.$refs.form as Vue & { reset: () => boolean };
  }
};
