import Vue from 'vue';
import Component from 'vue-class-component';
import {parse, toSeconds} from 'iso8601-duration';

@Component
export class Duration extends Vue {
    toSeconds(durationIso8601: string): number {
        if (!durationIso8601) {
            return 0;
        }

        return toSeconds(parse(durationIso8601));
    }

    toMinutes(durationIso8601: string): number {
        if (!durationIso8601) {
            return 0;
        }

        return toSeconds(parse(durationIso8601)) / 60;
    }

    toDuration(s) {
        const obj = parse(s);

        if (obj.years > 0) {
            return {
                value: obj.years,
                unit: {
                    token: 'year'
                }
            };
        }
        if (obj.months > 0) {
            return {
                value: obj.months,
                unit: {
                    token: 'month'
                }
            };
        }
        return {
            value: obj.days,
            unit: {
                token: 'day'
            }
        };
    }
}
