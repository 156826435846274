










































import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {Team, User} from "@/types/domain";

@Component({
  components: {}
})
export default class UsersTable extends mixins(Date) {
  @Prop({default: () => []})
  users!: Array<User>;

  headers: Array<object> = [
    {
      text: 'Rollen',
      value: 'roles',
    },
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'E-Mail',
      value: 'mail',
    },
    {
      text: 'Geburtsdatum',
      value: 'dateOfBirth',
      align: 'end',
    },
  ];
};
