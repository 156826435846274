import Vue from 'vue';
import Component from 'vue-class-component';
import axios from 'axios';

const cancelToken = axios.CancelToken;

@Component
export class AxiosMixin extends Vue {
    getNewCancelTokenSource() {
        try {
            if (cancelToken) {
                return cancelToken.source();
            }
            return undefined;
        } catch (e) {
        }
    }

    getToken(tokenSource: any) {
        try {
            if (tokenSource) {
                return tokenSource.token;
            }
            return undefined;
        } catch (e) {
        }
    }

    cancel(tokenSource: any) {
        try {
            if (tokenSource) {
                tokenSource.cancel();
            }
        } catch (e) {
        }
    }
}
