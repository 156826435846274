

























import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class CustomSkeletonLoader extends Vue {
  @Prop({default: true})
  isLoading!: boolean;

  @Prop({default: () => undefined})
  errorMessage!: string | undefined;

  @Prop({default: () => undefined})
  preset!: string | undefined;

  defaultType: string = 'card-heading, paragraph';
  types: Map<string, string> = new Map([
    ['team-form', 'card-heading, actions'],
    ['player-form', 'card-heading, actions'],
    ['category-form', 'card-heading, date-picker-days, actions'],
    ['module-form', 'card-heading, card-heading, paragraph, sentences, actions'],
    ['module-adjustment-form', 'card-heading, card-heading, paragraph, actions'],
    ['training-schedules', 'card-heading, card-heading, card-heading, card-heading'],
    ['training-schedule-form', 'card-heading, card-heading, card-heading, actions'],
    ['module-completed-form', 'card-heading, card-heading, card-heading, actions'],
    ['categories-component', 'actions'],
    ['modules-page', 'card-heading@3'],
    ['timeline-stats', 'text@2'],
    ['timeline', 'list-item-avatar@2'],
    ['coach-user', 'card-heading, card-heading'],
    ['position-card', 'card-heading, card-heading, card-heading, card-heading, card-heading, card-heading'],
    ['select', 'card-heading'],
  ]);

  get type() {
    if (!this.preset) {
      return this.defaultType;
    }

    const typeEntry = this.types.get(this.preset);
    if (typeEntry) {
      return typeEntry;
    }
    return this.defaultType;
  }

  get message() {
    return this.errorMessage ? this.errorMessage : this.$t('err.loadingData');
  }
}
