











import {Component, Prop} from 'vue-property-decorator';
import {User} from "@/types/domain";
import {mixins} from "vue-class-component";
import Vue from "vue";

@Component({
  components: {},
})
export default class HappyBirthdayComponent extends mixins() {
  @Prop({default: () => null})
  user: User;

  today: any = null;

  mounted() {
    this.today = Vue.prototype.dayjs();
  }

  get hasBirthday() {
    return this.user && (this.user.uid === '2' || this.user.uid === '1') && this.today && this.today.format('MM-DD') === '05-22';
  }

  get givenName() {
    if (!this.user) {
      return '';
    }
    const nameParts = this.user.name.split(' ');
    if (nameParts && nameParts.length > 0) {
      return nameParts[0];
    }

    return '';
  }
}
