



































import {Component} from 'vue-property-decorator';
import Vue from "vue";
import MetricsForm from "@/components/MetricsForm.vue";

@Component({
  components: {MetricsForm},
})
export default class MetricsComponent extends Vue {
  showComponent: boolean = false;
  showForm: boolean = false;

  mounted() {
    this.updateShowComponent();
  }

  updateShowComponent() {
    try {
      this.showComponent = false;
      const now = Vue.prototype.dayjs();
      if (this.$store.getters.metricsDismissedAt) {
        const metricsDismissedAtDate = Vue.prototype.dayjs(this.$store.getters.metricsDismissedAt);
        if (now.subtract(2, 'day').isBefore(metricsDismissedAtDate)) {
          return;
        }
      }
      if (!this.$store.getters.latestMetrics) {
        this.showComponent = true;
        return;
      }
      if (this.$store.getters.latestMetrics.dateTime) {
        const latestMetricsDateTime = Vue.prototype.dayjs(this.$store.getters.latestMetrics.dateTime);
        if (latestMetricsDateTime.isBefore(now.subtract(30, 'day'))) {
          this.showComponent = true;
        }
      }
    } catch (e) {
    }
  }

  dismissMetricsMessage() {
    // this.setCategoryFormClosed();
    this.showComponent = false;
    this.$store.dispatch('dismissMetricsMessage');
  }
}
