



















































































































import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import {Image, Module} from "@/types/domain";
import ModuleCompletedForm from "@/components/ModuleCompletedForm.vue";
import {Markdown} from "@/mixins/markdown";
import {ImageMixin} from "@/mixins/image";
import {Time} from "@/mixins/time";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {ModuleMixin} from "@/mixins/module";
import {Duration} from "@/mixins/duration";
import {Watch} from "vue-property-decorator";

export interface ModuleForView extends Module {
  markdownInstructions: string;
  markdownTools: string;
  formattedDuration: string;
}

@Component({
  components: {CustomSkeletonLoader, StandardErrorCard, ModuleCompletedForm}
})
export default class TheModulesPage extends mixins(Auth, Markdown, ImageMixin, Time, ModuleMixin, Duration) {
  breadcrumpItems: Array<any> = [
    {
      text: this.$t('txt.home'),
      disabled: false,
      to: {path: '/'},
    },
    {
      text: this.$route.query.categoryName,
      disabled: true,
      to: {name: 'modules'},
    },
  ];

  expansionPanelValue: number = -1;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  categoryImage: Image | null = null;
  modules: Array<ModuleForView> = [];

  selectedModule: Module | null = null;
  showModuleCompletedForm: boolean = false;

  mounted() {
    this.loadModules();
  }

  @Watch('expansionPanelValue')
  onExpansionPanelValueChanged() {
    // console.debug('expansionPanelValue', this.expansionPanelValue);
    // const moduleUid = this.getModuleUidFromIndex(this.expansionPanelValue);
    // this.loadModule(moduleUid, this.expansionPanelValue);
  }

  getModuleForView(module: Module): ModuleForView {
    return {
      ...module,
      markdownInstructions: this.getCompiledMarkdown(module.instructions),
      markdownTools: this.getCompiledMarkdown(module.tools),
      formattedDuration: this.toMinutes(module.duration) + ' min', // TODO translate
    };
  }

  getModuleUidFromIndex(index: number): string {
    if (index === undefined || index === null || !this.modules || this.modules.length - 1 < index) {
      return null;
    }

    return this.modules[index].uid;
  }

  loadModules() {
    this.wasLoadingSuccessful = false;
    this.isLoading = true;
    const categoryUid = this.$route.query.categoryUid;

    this.$http.get(`/api/users/current/categories/${categoryUid}`).then(response => {
      this.categoryImage = response.data.image;
      if (response.data && response.data.modules) {
        this.modules = [];
        response.data.modules.forEach(module => {
          this.modules.push(this.getModuleForView(module));
        });
      }

      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });

    // not necessary to load data; however, if the account was deactivated,
    // this returns 401 and the user will be locked out
    this.$http.get('/api/users/current');
  }

  loadModule(moduleUid: string, index: number) {
    if (!moduleUid) {
      return;
    }

    this.$http.get(`/api/users/current/modules/${moduleUid}`).then(response => {
      // const copy : Array<ModuleForView> = this.modules;
      // copy[index] = this.getModuleForView(response.data);
      // this.modules = copy;
      this.modules[index] = this.getModuleForView(response.data);
    }).finally(() => {
    });
  }

  selectModuleAndShowForm(module: Module): void {
    this.selectedModule = module;
    this.showModuleCompletedForm = true;
  }

  onSubmit() {
    this.showModuleCompletedForm = false;
    this.$router.push({name: 'landing'});
  }

  onCancel() {
    this.showModuleCompletedForm = false;
  }
};
