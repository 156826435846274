










import {Component, Prop} from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  components: {},
})
export default class StandardFormToolbar extends Vue {
  @Prop({default: ''})
  title!: string;
}
