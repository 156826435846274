











































































import Component, {mixins} from 'vue-class-component';
import ChartJsWrapper from "@/components/ChartJsWrapper.vue";
import {Position} from "@/types/domain";
import {ArrayMixin} from "@/mixins/arrayMixin";
import {Watch} from "vue-property-decorator";
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css';

Swiper.use([Navigation, Pagination]);

@Component({
  components: {ChartJsWrapper}
})
export default class TheClubPage extends mixins(ArrayMixin) {
  backgroundColors: any = {
    'TW': 'rgba(25,118,210,0.33)',
    'AL': 'rgba(193,0,0,0.33)',
    'RL': 'rgba(255,235,59,0.33)',
    'RM': 'rgba(76,175,80,0.33)',
    'KM': 'rgba(103,58,183,0.33)',
    'RR': 'rgba(255,152,0,0.33)',
    'AR': 'rgba(121,85,72,0.33)'
  };

  borderColors: any = {
    'TW': 'rgb(25,118,210)',
    'AL': 'rgb(193,0,0)',
    'RL': 'rgb(255,235,59)',
    'RM': 'rgb(76,175,80)',
    'KM': 'rgb(103,58,183)',
    'RR': 'rgb(255,152,0)',
    'AR': 'rgb(121,85,72)'
  };

  tab: any = null;

  clubConfig = {};
  clubOptions = {
    // indexAxis: 'y',
    scales: {
      'y': {
        display: false,
      }
    },
    legend: {
      display: false,
    },
  };

  ageGroupConfig = {};
  teamsConfig = {};

  radarOptions = {
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    scale: {
      ticks: {
        beginAtZero: true,
        min: 0,
        stepSize: 1
      }
    }
  };

  swiper: any = null;

  mounted() {
    this.loadClubStats();
    this.loadAgeGroupStats();
    this.loadTeamStats();
  }

  @Watch('tab')
  onTabChanged() {
    this.loadClubStats();
  }

  @Watch('$vuetify.breakpoint.width')
  onWidthChanged() {
    this.initSwiper();
  }

  initSwiper() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.swiper = new Swiper('.swiper-container', {
          slidesPerView: 1,
          slidesPerColumn: 1,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
        });
      }, 200);
    });
  }

  loadClubStats() {
    this.$http.get(`/api/positions/stats1`).then(response => {
      if (this.tab === 'tab-radar') {
        this.clubConfig = this.getRadarConfig(response);
      } else {
        this.clubConfig = this.getPieConfig(response);
      }
    }).then(() => {
      this.initSwiper();
    }).finally(() => {
    });
  }

  getPieConfig(response: any) {
    return {
      type: 'pie',
      data: {
        labels: response.data.map((position: Position) => position.name),
        datasets: [{
          data: response.data.map((position: Position) => position.numPlayers),
          fill: false,
          backgroundColor: Array.from(Object.values(this.backgroundColors)),
          borderColor: Array.from(Object.values(this.borderColors)),
          borderWidth: 1
        }]
      },
      options: this.clubOptions,
    };
  }

  getRadarConfig(response: any) {
    return {
      type: 'radar',
      data: {
        labels: response.data.map((position: Position) => position.name),
        datasets: [{
          label: 'Gesamter Verein',
          data: response.data.map((position: Position) => position.numPlayers),
          fill: true,
          backgroundColor: this.backgroundColors['TW'],
          borderColor: this.borderColors['TW'],
          pointBackgroundColor: this.borderColors['TW'],
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(54, 162, 235)',
        }]
      },
      options: this.radarOptions,
    };
  }

  loadAgeGroupStats() {
    this.$http.get(`/api/positions/stats`).then(response => {
      const datasets = new Map();
      const responseData = response.data;
      if (responseData && responseData.length > 0) {
        const labels: Array<string> = responseData.map((position: Position) => position.name);

        responseData.forEach((position: Position) => {
          let i = 0;
          Object.keys(position.ageGroupsMap).forEach((key: string) => {
            let dataset = datasets.get(key);
            if (!dataset) {
              let index = Object.keys(this.backgroundColors)[i];
              dataset = {
                label: !key || key === 'null' ? 'unbekannter Jahrgang' : key + '',
                data: [position.ageGroupsMap[key]],
                fill: true,
                backgroundColor: this.backgroundColors[index],
                borderColor: this.borderColors[index],
                pointBackgroundColor: this.borderColors[index],
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(54, 162, 235)'
              };
            } else {
              dataset.data.push(position.ageGroupsMap[key]);
            }
            datasets.set(key, dataset);
            i++;
          });
        });

        this.ageGroupConfig = {
          type: 'radar',
          data: {
            labels,
            datasets: Array.from(datasets.values()),
          },
          options: this.radarOptions,
        };
      }
    }).then(() => {
      this.initSwiper();
    }).finally(() => {
    });
  }

  loadTeamStats() {
    this.$http.get(`/api/positions/team-stats`).then(response => {
      const datasets = new Map();
      const responseData = response.data;
      if (responseData && responseData.length > 0) {
        const labels: Array<string> = responseData.map((position: Position) => position.name);

        responseData.forEach((position: Position) => {
          let i = 0;
          Object.keys(position.teamNamesMap).forEach((key: string) => {
            let dataset = datasets.get(key);
            if (!dataset) {
              let index = Object.keys(this.backgroundColors)[i];
              dataset = {
                label: !key || key === 'null' ? 'n/a' : key + '',
                data: [position.teamNamesMap[key]],
                fill: true,
                backgroundColor: this.backgroundColors[index],
                borderColor: this.borderColors[index],
                pointBackgroundColor: this.borderColors[index],
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgb(54, 162, 235)'
              };
            } else {
              dataset.data.push(position.teamNamesMap[key]);
            }
            datasets.set(key, dataset);
            i++;
          });
        });

        this.teamsConfig = {
          type: 'radar',
          data: {
            labels,
            datasets: Array.from(datasets.values()),
          },
          options: this.radarOptions,
        };
      }
    }).then(() => {
      this.initSwiper();
    }).finally(() => {
    });
  }
};
