import Vue from 'vue';
import Component from 'vue-class-component';
import {Module, ModuleAdjustment, User} from "@/types/domain";

@Component
export class ModuleMixin extends Vue {
    dayjs = Vue.prototype.dayjs;

    getInstructions(module: Module, user: User | null = null): string | null {
        if (!module) {
            return null;
        }

        const adjustment: ModuleAdjustment | null = this.getAdjustment(module, user);
        if (!adjustment) {
            return module.instructions;
        }
        return adjustment.instructions;
    }

    getDuration(module: Module, user: User | null = null): string | null {
        if (!module) {
            return null;
        }

        const adjustment: ModuleAdjustment | null = this.getAdjustment(module, user);
        if (!adjustment) {
            return module.duration;
        }
        return adjustment.duration;
    }

    getAdjustment(module: Module, user: User | null): ModuleAdjustment | null {
        if (!user) {
            user = this.$store.getters.user;
        }

        if (!user || !module || !module.adjustments) {
            return null;
        }
        const adjustment = module.adjustments.find(adjustment => adjustment.players ? adjustment.players.map(player => player.uid).includes(user ? user.uid : '--') : false);
        if (!adjustment) {
            return null;
        }
        return adjustment;
    }
}
