






























































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Category} from "@/types/domain";
import {mixins} from "vue-class-component";
import {ImageMixin} from "@/mixins/image";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";

Swiper.use([Navigation, Pagination]);

@Component({
  components: {CustomSkeletonLoader, StandardErrorCard},
})
export default class CategoriesComponent extends mixins(ImageMixin) {
  categories: Array<Category> = [];

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = true;

  cardHeight: number = 180;
  cardWidth: number = 180;

  swiper: any = null;

  mounted() {
    this.loadCategories();
  }

  initSwiper() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.swiper = new Swiper('.swiper-container', {
          slidesPerView: this.numSlidesPerView,
          slidesPerColumn: this.numSlidesPerColumn,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          },
        });
      }, 200);
    });
  }

  @Watch('$vuetify.breakpoint.width')
  onWidthChanged() {
    this.initSwiper();
  }

  @Watch('$store.getters.teamUid')
  onTeamSelectionChanged() {
    this.loadCategories();
  }

  loadCategories() {
    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http.get(`/api/users/current/categories`).then(response => {
      this.categories = response.data;
    }).then(() => {
      this.wasLoadingSuccessful = true;
    }).then(() => {
      this.initSwiper();
    }).finally(() => {
      this.isLoading = false;
    });
  }

  get swiperContainerHeight() {
    return this.numSlidesPerColumn * this.cardHeight + 30;
  }

  get numSlidesPerView() {
    const currentWidth = this.$vuetify.breakpoint.width;
    return Math.max(1, Math.floor(currentWidth / this.cardWidth));
  }

  get numSlidesPerColumn() {
    if (this.$vuetify.breakpoint.width > 700) {
      return 1;
    }
    const currentHeight = this.$vuetify.breakpoint.height;
    return Math.min(2, Math.max(1, Math.floor((currentHeight - 180) / this.cardHeight)));
  }
}
