











































import {Component} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {AxiosMixin} from "@/mixins/axios";

@Component({
  components: {StandardFormButtons, StandardFormToolbar, ErrorOverlay},
})
export default class ConfirmationDialog extends mixins(AxiosMixin) {
  dialog: boolean = false;

  resolve: any = null;
  reject: any = null;

  title: string = '';
  message: string = '';

  open(title: string, message: string) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  agree() {
    this.resolve(true);
    this.dialog = false;
  }

  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
