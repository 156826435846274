






















































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import Vue from 'vue';
import {Module, User} from "@/types/domain";
import StandardFormToolbar from "@/components/StandardFormToolbar.vue";
import ErrorOverlay from "@/components/ErrorOverlay.vue";
import CustomSkeletonLoader from "@/components/CustomSkeletonLoader.vue";
import {mixins} from "vue-class-component";
import {AxiosMixin} from "@/mixins/axios";
import {CancelTokenSource} from "axios";
import StandardFormButtons from "@/components/StandardFormButtons.vue";
import {Duration} from "@/mixins/duration";
import {EventInput, MetricsInput, ModuleCompletedFormInput} from "@/types/app/forms";
import {Date} from "@/mixins/date";

@Component({
  components: {StandardFormButtons, CustomSkeletonLoader, ErrorOverlay, StandardFormToolbar},
})
export default class ModuleCompletedForm extends mixins(AxiosMixin, Duration, Date) {
  @Prop({default: false})
  show!: boolean;

  @Prop({default: () => null})
  module!: Module;

  @Prop({default: () => null})
  initialDate: string | null;

  isLoading: boolean = true;
  isSaving: boolean = false;

  showErrorMessage: boolean = false;
  errorMessage: string = '';

  user: User | null = null;

  dateOfTraining: string | null = null;
  timeOfTraining: string | null = null;
  menu: boolean = false;
  menu2: boolean = false;

  bodyHeightInCm: number = 150;
  bodyWeightInKg: number = 40;

  showDialog: boolean = false;
  valid: boolean = false;

  players: Array<User> = [];

  trainingPartners: Array<User> = [];

  intervalHandle: any = null;
  intervalCounter: number = 0;

  trainingDays: Array<any> = [];

  cancelTokenSource: CancelTokenSource | undefined;

  mounted() {
    const now = Vue.prototype.dayjs();
    const minutesToNextFullHour = 60 - now.minute();
    const millisecondsToWait = minutesToNextFullHour * 60 * 1000;
    setTimeout(this.scheduleAutomaticUpdates, millisecondsToWait); // run at the next top of the hour
  }

  beforeDestroy() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle);
    }
  }

  resetForm() {
    if (this.$refs.form) {
      const form: any = this.$refs.form;
      form.resetValidation();
    }
  }

  @Watch('show')
  onShowChanged() {
    this.showDialog = this.show;

    if (this.show) {
      this.resetForm();
      this.reloadFormData();

      if (this.$store.getters.latestMetrics) {
        if (this.$store.getters.latestMetrics.bodyHeightInCm) {
          this.bodyHeightInCm = this.$store.getters.latestMetrics.bodyHeightInCm;
        }
        if (this.$store.getters.latestMetrics.bodyWeightInKg) {
          this.bodyWeightInKg = this.$store.getters.latestMetrics.bodyWeightInKg;
        }
      }

      this.showErrorMessage = false;
      this.errorMessage = '';
      this.isLoading = false;

      this.user = null;
      this.trainingPartners = [];

      if (this.initialDate) {
        this.dateOfTraining = this.initialDate;
      }
    } else {
      this.cancel(this.cancelTokenSource);
    }
  }

  scheduleAutomaticUpdates() {
    this.intervalHandle = setInterval(this.executeTimer, 60 * 60 * 1000); // every 60 minutes
  }

  setTrainingDays() {
    const now = Vue.prototype.dayjs();

    this.trainingDays = [
      {
        value: now.format("YYYY-MM-DD"),
        text: this.$t('txt.today')
      },
      {
        value: now.subtract(1, 'day').format("YYYY-MM-DD"),
        text: this.$t('txt.yesterday')
      },
      {
        value: now.subtract(2, 'day').format("YYYY-MM-DD"),
        text: now.subtract(2, 'day').format('dddd')
      }
    ];
  }

  executeTimer() {
    this.intervalCounter++;

    if (this.intervalCounter > 24) {
      clearInterval(this.intervalHandle);
      return;
    }

    this.reloadFormData();
  }

  reloadFormData() {
    this.updateDateOfTraining();
    this.updateTimeOfTraining();
    this.loadPlayers();
  }

  updateDateOfTraining() {
    this.setTrainingDays();

    const trainingDays = this.trainingDays;
    if (!trainingDays || trainingDays.length <= 0) {
      return;
    }

    if (this.dateOfTraining === null) {
      this.dateOfTraining = trainingDays[0].value;
      return;
    }

    for (let i = 0; i < trainingDays.length; i++) {
      if (trainingDays[i].value === this.dateOfTraining) {
        this.dateOfTraining = trainingDays[i].value;
        return;
      }
    }

    this.dateOfTraining = trainingDays[trainingDays.length - 1].value;
  }

  updateTimeOfTraining() {
    try {
      const now = Vue.prototype.dayjs();
      const start = now.subtract(this.toMinutes(this.module.duration), 'minute');
      this.timeOfTraining = start.format('HH:00');
    } catch (e) {
      this.timeOfTraining = '17:00';
    }
  }

  loadPlayers() {
    this.$http.get(`/api/teams/players`).then(response => {
      this.players = response.data;
    });
  }

  save() {
    this.cancelTokenSource = this.getNewCancelTokenSource();
    this.isSaving = true;
    const event: EventInput = {
      userUid: this.user ? this.user.uid : undefined,
      dateTimeOfTraining: this.dateOfTraining + 'T' + this.timeOfTraining,
      trainingPartnerUids: this.trainingPartners.map(player => player.uid),
      teamUids: this.$store.getters.teams.map(team => team.uid),
    };
    const metrics: MetricsInput = {
      bodyHeightInCm: this.bodyHeightInCm,
      bodyWeightInKg: this.bodyWeightInKg,
    };
    const data: ModuleCompletedFormInput = {
      event,
      metrics
    };

    this.$http.post(`/api/modules/${this.module.uid}/complete`, data, {
      cancelToken: this.getToken(this.cancelTokenSource),
    }).then(() => {
      this.$emit('submit');
      this.$store.dispatch('updateLatestMetrics', {
        dateTime: Vue.prototype.dayjs().format('YYYY-MM-DDTHH:mm:ss'),
        bodyHeightInCm: this.bodyHeightInCm,
        bodyWeightInKg: this.bodyWeightInKg,
      });
    }).catch(() => {
      this.setErrorMessage(this.$t('err.save').toString());
    }).finally(() => {
      this.isSaving = false;
    });
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg;
    this.showErrorMessage = true;
  }

  closeDialog() {
    this.$emit('cancel');
  }

  allowedHours(v: any) {
    return v > 5 && v < 23;
  }

  allowedMinutes(v: any) {
    return v === 0 || v === 30;
  }

  selectAlone() {
    this.$nextTick(() => {
      const selectElement: any = this.$refs.partners;
      if (selectElement) {
        selectElement.isMenuActive = false;
      }
      this.trainingPartners = [];
    });
  }

  closePartners() {
    this.$nextTick(() => {
      const selectElement: any = this.$refs.partners;
      if (selectElement) {
        selectElement.isMenuActive = false;
      }
    });
  }

  get computedPlayers() {
    const currentUser = this.$store.getters.user;
    if (currentUser) {
      return this.players.filter(user => user.uid !== currentUser.uid);
    }
    return this.players;
  }

  get isDisabled() {
    return this.isSaving;
  }

  get showSkeletonLoader() {
    return this.isLoading;
  }

  get formattedDate() {
    return this.formatDateForDatePicker(this.dayjs(this.dateOfTraining));
  }
}
