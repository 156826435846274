































































import Component, {mixins} from 'vue-class-component';
import {ArrayMixin} from "@/mixins/arrayMixin";
import {Date} from "@/mixins/date";
import CalendarWeekView from "@/components/trainingSchedule/CalendarWeekView.vue";
import {Calendar, ModuleForCalendar} from "@/types/domain";

@Component({
  components: {CalendarWeekView}
})
export default class TheTrainingSchedulePage extends mixins(ArrayMixin, Date) {
  breadcrumpItems: Array<any> = [
    {
      text: this.$t('txt.home'),
      disabled: false,
      to: {path: '/'},
    },
    {
      text: this.$t('txt.calendar'),
      disabled: true,
      to: {},
    },
  ];

  calendar: Calendar = null;

  mounted() {
    this.loadTrainingSchedules();
  }

  loadTrainingSchedules() {
    const params = {
      start: this.dayjs().weekday(-7).format('YYYY-MM-DD'),
      end: this.dayjs().weekday(13).format('YYYY-MM-DD'),
    };
    this.$http.get(`/api/users/current/calendar`, {params}).then(response => {
      this.calendar = response.data;

      this.calendar.trainingSchedules.forEach(schedule => {
        const map = new Map();
        schedule.events.forEach(event => {
          let entry = map.get(event.dateIsoString);
          if (!entry) {
            entry = [];
          }
          entry.push(event);
          map.set(event.dateIsoString, entry);
        });
        schedule.eventsMap = map;
      });
    });
  }

  get modulesMap(): Map<string, ModuleForCalendar> {
    const map: Map<string, ModuleForCalendar> = new Map();

    if (this.calendar && this.calendar.modules) {
      this.calendar.modules.forEach(module => {
        map.set(module.uid, module);
      });
    }
    return map;
  }

  get eventsMap() {
    const map = new Map();

    if (this.calendar && this.calendar.completedEventsWithoutSchedule) {
      this.calendar.completedEventsWithoutSchedule.forEach(event => {
        let entry = map.get(event.dateIsoString);
        if (!entry) {
          entry = [];
        }
        entry.push(event.moduleUid);
        map.set(event.dateIsoString, entry);
      });
    }
    return map;
  }
};
