




































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {Module, RecurrenceConfig} from "@/types/domain";
import {mixins} from "vue-class-component";
import {Recurrence} from "@/types/app";
import {Duration} from "@/mixins/duration";
import Vue from "vue";
import {Date} from "@/mixins/date";
import {Recurrence as Recur} from "@/mixins/recurrence";
import TrainingScheduleForm from "@/components/TrainingScheduleForm.vue";

@Component({
  components: {},
})
export default class ModuleRecurrenceForm extends mixins(Duration, Date, Recur) {
  @Prop()
  value!: RecurrenceConfig;

  @Prop()
  module!: Module;

  menuStart: boolean = false;
  customizeDays: boolean = false;

  moduleConfig: RecurrenceConfig = null;

  mounted() {
    this.updateModuleConfigFromValue();
  }

  @Watch('value')
  onValueChanged(): void {
    this.updateModuleConfigFromValue();
  }

  updateModuleConfigFromValue() {
    this.moduleConfig = this.value;
    if (!this.moduleConfig.numberOfRepeats || this.moduleConfig.numberOfRepeats < 1) {
      const initialConfig = TrainingScheduleForm.getInitialRecurrenceConfig();
      this.moduleConfig.numberOfRepeats = initialConfig.numberOfRepeats;
    }
    if (!this.moduleConfig.interval || this.moduleConfig.interval <= 1) {
      const initialConfig = TrainingScheduleForm.getInitialRecurrenceConfig();
      this.moduleConfig.interval = initialConfig.interval;
    }
    if (this.moduleConfig.weekdays && this.moduleConfig.weekdays.length >= 1) {
      this.customizeDays = true;
    }
  }

  removeModuleFromTrainingSchedule() {
    this.$emit('remove', this.module.uid);
  }

  get formattedStartDate() {
    return this.formatDateForDatePicker(Vue.prototype.dayjs(this.moduleConfig.startDate));
  }

  get availableRecurrences(): Array<Recurrence> {
    // if (!this.trainingSchedule) {
    //   return [];
    // }

    const startDate = this.dayjs(this.moduleConfig.startDate);
    const dayOfWeek = this.dayjs.weekdays()[startDate.day()];
    const nthWeekdayInMonth = Math.ceil(startDate.date() / 7);
    const dayOfMonthText = (nthWeekdayInMonth >= 5 ? this.$t('txt.last') : nthWeekdayInMonth + '.') + ' ' + dayOfWeek;
    const dayOfYear = startDate.date() + '. ' + this.dayjs.months()[startDate.month()];

    const weeklyText = this.moduleConfig.weekdays && this.moduleConfig.weekdays.length > 0
        ? this.$t('txt.recurrence.weekly').toString() + ' ' + this.formatWeekdays(this.moduleConfig.weekdays) : this.$t('txt.recurrence.WEEKLY', {day: dayOfWeek}).toString();
    return [
      {token: 'ONCE', text: this.$t('txt.recurrence.ONCE').toString()},
      {token: 'DAILY', text: this.$t('txt.recurrence.DAILY').toString()},
      {token: 'WEEKLY', text: weeklyText},
      {token: 'MONTHLY', text: this.$t('txt.recurrence.MONTHLY', {day: dayOfMonthText}).toString()},
      {token: 'YEARLY', text: this.$t('txt.recurrence.YEARLY', {day: dayOfYear}).toString()},
    ];
  }

  get availableWeekdays() {
    return [
      {
        token: 'MO',
        text: 'Mo.',
      },
      {
        token: 'TU',
        text: 'Di.',
      },
      {
        token: 'WE',
        text: 'Mi.',
      },
      {
        token: 'TH',
        text: 'Do.',
      },
      {
        token: 'FR',
        text: 'Fr.',
      },
      {
        token: 'SA',
        text: 'Sa.',
      },
      {
        token: 'SU',
        text: 'So.',
      },
    ];
  }
}
