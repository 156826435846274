import Vue from 'vue';
import Component from 'vue-class-component';
import {Image} from "@/types/domain";

@Component
export class ImageMixin extends Vue {
    getImageUrl(image: Image | null) {
        if (!image) {
            return;
        }
        return image.url;
    }

    // toImageData(image: Image | null) {
    //     if (image === null) {
    //         return;
    //     }
    //     return 'data:' + image.contentType + ';base64,' + image.data;
    // }

    encodeUnicode(str: any) {
        // first we use encodeURIComponent to get percent-encoded UTF-8
        const encoded = encodeURIComponent(str);

        // then we convert the percent encodings into raw bytes which can be fed into btoa
        const bytes = encoded.replace(/%([0-9A-F]{2})/g,
            (match, p1) => {
                return String.fromCharCode(parseInt(p1, 16));
            }
        );

        return btoa(bytes);
    }
}
