






















































import Component, {mixins} from 'vue-class-component';
import {Auth} from '@/mixins/auth';
import {Category, Module, User} from "@/types/domain";
import {Markdown} from "@/mixins/markdown";
import CategoryForm from "@/components/CategoryForm.vue";
import ModuleAdjustmentForm from "@/components/ModuleAdjustmentForm.vue";
import {Time} from "@/mixins/time";
import {ImageMixin} from "@/mixins/image";
import {Watch} from "vue-property-decorator";
import StandardErrorCard from "@/components/StandardErrorCard.vue";
import CategoryPanels from "@/components/coach/CategoryPanels.vue";

@Component({
  components: {CategoryPanels, StandardErrorCard, ModuleAdjustmentForm, CategoryForm}
})
export default class TheTeamModulesPage extends mixins(Auth, Markdown, Time, ImageMixin) {
  categories: Array<Category> = [];
  selectedUser: User | null = null;

  showCategoryForm: boolean = false;
  selectedCategoryUid: string | null = null;

  selectedCategory: Category | null = null;

  selectedModuleUid: string | null = null;

  selectedModule: Module | null = null;

  isLoading: boolean = false;
  wasLoadingSuccessful: boolean = false;

  mounted() {
    this.loadCategories();
  }

  @Watch('$store.getters.teamUid')
  onTeamSelectionChanged() {
    this.$router.push({name: 'coach-team-modules', params: {teamUid: this.$store.getters.teamUid}});
  }

  @Watch('$route.params.teamUid')
  onTeamUidChanged() {
    this.loadCategories();
  }

  loadCategories() {
    const teamUid = this.getSelectedTeamUid();

    if (!teamUid) {
      return;
    }

    this.isLoading = true;
    this.wasLoadingSuccessful = false;
    this.$http.get(`/api/categories`).then(response => {
      this.categories = response.data;
      this.wasLoadingSuccessful = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  onCategoriesChanged(category) {
    // TODO define correct type for category and update the view without loading again
    this.loadCategories();
    this.setCategoryFormsClosed();
  }

  onCategoryDeleted(categoryUid: string) {
    if (this.categories && categoryUid) {
      this.categories = this.categories.filter(category => category.uid !== categoryUid);
    }
    this.setCategoryFormsClosed();
  }

  selectUser(user: User) {
    this.selectedUser = user;
  }

  selectCategoryAndShowForm(categoryUid: string | null) {
    this.selectedCategoryUid = categoryUid;
    this.showCategoryForm = true;
  }

  setCategoryFormsClosed() {
    this.showCategoryForm = false;
  }

  getSelectedTeamUid() {
    return this.$store.getters.teamUid;
  }

  get isCoach() {
    return this.$store.getters.hasCoachRole;
  }
};
