













































































import {mixins} from 'vue-class-component';
import {Component, Prop} from 'vue-property-decorator';
import {Date} from '@/mixins/date';
import {Position, Team} from "@/types/domain";

@Component({
  components: {}
})
export default class TeamTable extends mixins(Date) {
  @Prop({default: {}})
  team!: Team;

  headers: Array<object> = [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'E-Mail',
      value: 'mail',
    },
    {
      text: 'Positionen',
      value: 'positions',
      sort: this.sortPositions,
    },
    {
      text: 'Geburtsdatum',
      value: 'dateOfBirth',
      align: 'end',
    },
  ];

  sortPositions(positions1: Array<Position>, positions2: Array<Position>): number {
    if (!positions1 && !positions2) {
      return 0;
    }

    if (!positions1) {
      return 1;
    }

    if (!positions2) {
      return -1;
    }

    let i = 0;
    while (true) {
      if (i >= positions1.length && i >= positions2.length) {
        return 0;
      }

      if (i >= positions1.length) {
        return 1;
      }

      if (i >= positions2.length) {
        return -1;
      }

      if (positions1[i].order < positions2[i].order) {
        return -1;
      }

      if (positions1[i].order > positions2[i].order) {
        return 1;
      }

      i++;
    }
  }

  get isCoach() {
    return this.$store.getters.hasCoachRole;
  }
};
