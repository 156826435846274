import { render, staticRenderFns } from "./Reporting.vue?vue&type=template&id=76880014&scoped=true&"
import script from "./Reporting.vue?vue&type=script&lang=ts&"
export * from "./Reporting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76880014",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VSparkline})
